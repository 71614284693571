import React from 'react'
import "./FollowMe.css"




function FollowMe() {
  return (
    <div className='FollowMe-container py-5'>
        
            <div className='follow-container my-3 '>
                <div className='container'>

                <div className='row my-4'>
                    <div className='col-lg-6'>
                        <div className='follow-text'>
                            <h3 className='body-heading color-white'>FOLLOW ME</h3>
                            <p className='body-paragraph color-white my-3'>Lorem ipsum dolor sit amet in quis, consectetur eros adipiscing et elit. Suspendisse varius enim in eros elementum tristique.</p>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-4'>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-1.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-2.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-3.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-4.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-5.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-6.svg'/>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <img src='/images/creatives/medal-icon.png' className='medal-icon' alt='medal icon'/>

                    </div>

                </div>
                </div>

            </div>
    </div>

  )
}

export default FollowMe