import React from 'react'
import "./MyServices.css"
import { useNavigate } from 'react-router-dom'

const cardData = [
    {
        icon:"/images/icons/service-card-1.png",
        title:"Google Ads",
        content:"Maximize your online presence with targeted Google Ads campaigns. Salman Baig ensures your brand reaches the right audience, driving traffic and increasing conversions. Let’s turn clicks into loyal customers.",
        link:"/google-ads",
    },
    {
        icon:"/images/icons/service-card-2.png",
        title:"Search Engine Optimization",
        content:"Boost your website’s visibility with expert SEO strategies. Salman’s techniques improve your search rankings, bringing organic traffic and enhancing your brand’s online authority. Get found by those who matter.",
        link:"/search-engine-optimization",
    },
    {
        icon:"/images/icons/service-card-3.png",
        title:"WEB DESIGN & DEVELOPMENT",
        content:"Create stunning, user-friendly websites that captivate and convert. Salman combines creative design with robust development to build digital experiences that resonate with your audience. Your dream website starts here.",
        link:"/user-interface",
    },
    {
        icon:"/images/icons/service-card-4.png",
        title:"Social Media Marketing",
        content:"Elevate your brand’s social media game with engaging and strategic campaigns. Salman crafts content that connects, engages, and grows your online community. Let’s make your social presence shine.",
        link:"/social-media-marketing",
    },
]

function ServiceCard({
    icon,
    title,
    content,
    link="#"
})
{
    const navigate = useNavigate();
    return(
        <div className='service-card-container p-4 pt-5 mb-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col-3 icon-container'>
                        <img className='img-fluid' alt='service icon' src={icon} />
                    </div>

                    <div className='col-8'>
                        <h3 className='color-blue card-title font-bebus' style={{textTransform:"uppercase"}}>{title}</h3>
                        <p className='body-paragraph card-content my-1'>{content}</p>

                        <p 
                            className='body-paragraph card-anchor mb-0' 
                            style={{textDecoration:"underline"}}
                            onClick={()=>{navigate(link)}}
                        >
                            More</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

function MyServices() {
    const navigate = useNavigate()
  return (
    <div className='MyServices-container py-5'>
        <div className='container my-5 py-2'>
            <div className='main row gy-4'>
                <div className='text-content col-lg-6 text-center text-lg-start'>
                    <h3 className='font-bebus body-heading color-blue'>
                        What Can I Do  <span className='color-yellow'>for You?</span>
                    </h3>
                    <p className='body-paragraph font-manrope my-4 mx-auto mx-lg-0' style={{color:'#313131',width:"90%"}}>Let's Make Your Brand Stand Out, Boost Your Online Presence, and Propel Your Success to New Heights!</p>
                    <button className='genral-btn btn-blue mt-2' onClick={()=>{navigate("/contact")}}>CONTACT ME</button>
                </div>

                <div className='sliding-card col-lg-6'>
                    <div className='card-sized-box'>

                        {
                            cardData.map((ele)=>{
                                return(
                                    <ServiceCard
                                        icon={ele.icon}
                                        title={ele.title}
                                        content={ele.content}
                                        link={ele.link}
                                    />
                                )
                            })
                        }
                       
                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default MyServices