import React from 'react'
import BlogMainHeroBanner from '../components/BlogMainHeroBanner/BlogMainHeroBanner'
import BlogsListing from '../components/BlogsListing/BlogsListing'
import SubscribeNewsletter from '../components/SubscribeNewsletter/SubscribeNewsletter'
import Footer from '../components/Footer/Footer'

function Blogs() {
  return (
    <>
        <BlogMainHeroBanner/>

        <BlogsListing/>

        <SubscribeNewsletter/>

        <Footer/>

    </>
  )
}

export default Blogs