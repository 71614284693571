import React from 'react'
import { useNavigate } from 'react-router-dom'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function BlogCard({
    content,
    img,
    title,
    anchor,
    redirectionFunction,
    date,
    catagory
}) {

  const navigate = useNavigate()

  const breifPara = truncateText(content,120)
  const breifTitle = truncateText(title,50)

  return (
    <div className='blog-card mb-5' >
       
        <img src={img} className='card-img my-3' alt='blof thumbnail'/>
        <div className='d-flex align-items-center gap-2 font-manrope'  style={{color:'#EAEBEB'}}>
          <p className='font-manrope mb-0'>{`${catagory}`}</p>
          <div style={{border:"1px solid #EAEBEB",width:"35px"}}></div>
          <p className='font-manrope mb-0'>{`${date}`}</p>
        </div>
        <h3 className='title text-md-start text-center my-3 color-white font-manrope'>{breifTitle}</h3>
        <div className='body-paragraph font-manrope text-md-start text-center'  style={{color:'#EAEBEB'}} dangerouslySetInnerHTML={{ __html: breifPara }}></div>

        <div 
          className='d-flex align-items-center gap-2 mt-4'
          onClick={()=>{redirectionFunction(anchor)}}
        >
            <p className='font-manrope mb-0 blog-card-anchor' style={{color:'#EAEBEB'}}>Read Article</p>
            <img src='./images/icons/case-st-card-icon.svg' alt="arrow icon" className='blog-card-icon'/>
        </div>
       
    </div>
  )
}

export default BlogCard