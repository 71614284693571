import React, { useEffect, useState } from 'react'
import BlogInnerHeroBanner from '../components/BlogInnerHeroBanner/BlogInnerHeroBanner'
import BlogContent from '../components/BlogContent/BlogContent'
import ReviewBox from '../components/ReviewBox/ReviewBox'
import Footer from "../components/Footer/Footer"
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function BlogInner() {
  const [blogsData, setBlogsData] = useState();

  const [loading, setLoading] = useState(true);

  const id  = useParams().id;

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          
          let blog = res.data.data.find((item) => item.slug_url == id);
          setBlogsData(blog);

          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setBlogsData(null)
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);

  return (
    <>
    {
      loading?
        <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
        >
            <Spinner
            style={{ color: "black", width: "120px", height: "120px" }}
            />
        </div>
      :
      <>
      {
        blogsData && 
        <>
            <Helmet>
              {blogsData?.metaTitle && <title>{blogsData.metaTitle}</title>}
              {blogsData?.metaDescription && <meta name='description' content={blogsData.metaDescription} />}
              {blogsData?.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
            </Helmet>
          <BlogInnerHeroBanner
              heading={blogsData.title}
              date={blogsData.published_date}
              catagory={blogsData.catagory}
          />

          <img src="/images/creatives/blog-innner.png" className='img-fluid'/>

          <BlogContent
            blogsData={blogsData.blogs_content}
            text={blogsData.brief_paragraph}
          />
        </>
      }
      </>
    }
        

        <ReviewBox/>

        <Footer/>
    </>
  )
}

export default BlogInner