import React,{useState,useEffect} from 'react'
import "./PopularBlogs.css"
import BlogCard from './BlogCard'
import PopularBlogCard from './PopularBlogCard'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
// import axios from "axios"
import {useNavigate} from "react-router-dom"
import {Spinner} from "react-bootstrap"


const blogData = [
  {
    catagory: "Design",
    date: "Jan 30,2024",
    main_heading:"Font sizes in Branding design: The complete guide to follow",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing",
    blog_creative: "/images/creatives/blog-card-img-1.png",
    slug_url: "bloginner",
  },
  {
    catagory: "Design",
    date: "Jan 30,2024",
    main_heading:"Font sizes in Branding design: The complete guide to follow",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing",
    blog_creative: "/images/creatives/blog-card-img-1.png",
    slug_url: "bloginner",
  },
  {
    catagory: "Design",
    date: "Jan 30,2024",
    main_heading:"Font sizes in Branding design: The complete guide to follow",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing",
    blog_creative: "/images/creatives/blog-card-img-1.png",
    slug_url: "bloginner",
  },
  {
    catagory: "Design",
    date: "Jan 30,2024",
    main_heading:"Font sizes in Branding design: The complete guide to follow",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing",
    blog_creative: "/images/creatives/blog-card-img-1.png",
    slug_url: "bloginner",
  },
  
];

let defaultBlogObj= [];

function PopularBlogs({selectedCatagory,blogsData,catagoryList}) {
  const [blogs, setBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  const getAllBlogsByCatagory = ()=>{

    if(selectedCatagory == 0)
    {
      setLoading(false)
      setBlogs(blogsData)
      return
    }
    
    setLoading(false)
    setBlogs(blogsData.filter(blog => blog.category === selectedCatagory));
  }

  useEffect(() => {
    getAllBlogsByCatagory();
  }, [selectedCatagory]);
  
  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);
    navigate(`/blog/${slug}`);

  };

  return (
    <div className='popular-blog-container standard-padding-space'>
      {
        loading ?
          <div
              style={{ width: "100%", height: "100vh" }}
              className="d-flex justify-content-center align-items-center"
          >
              <Spinner
              style={{ color: "white", width: "120px", height: "120px" }}
              />
          </div>

          :
          <div className='container-fluid'>
              <Swiper
                  spaceBetween={40}
                  autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Autoplay]}
                  breakpoints={{
                      200:{
                      slidesPerView: 1,
                      },
                      1000: {
                      slidesPerView: 3,
                      },
                  }}
                  className={`mx-auto py-3`}
                  >
                  
                  {
                      blogs.map((ele)=>{
                        
                          return(
                            <SwiperSlide>
                              <BlogCard
                                  title={ele.main_heading}
                                  anchor={ele.slug_url}
                                  redirectionFunction={redirectUserToBlog}
                                  content={ele.paragraph}
                                  img={ele.blog_creative}
                                  catagory={ele.catagory}
                                  date={ele.date}
                              />
                            </SwiperSlide>
                              )
                      })
                  }
                  </Swiper>
          </div>

      }
       
    </div>
  )
}

export default PopularBlogs