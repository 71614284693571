import React from 'react'
import "./CaseStContent.css"

function CaseStContent({
    heading,
    para,
    para2,
    img,
    heading2,
    para3,
    heading3,
    para4,
    heading4,
    para5,
    heading5,
    para6,
    heading6,
    para7,
    heading7,
    para8,
    heading8,
    para9,
    heading9,
    para10,
    heading10,
    para11,
    paddingBottom,
    paddingTop,
  }) {
    return (
        <div
          className='standard-padding-space case-st-content'
        >
          <div className='container pt-5 pb-5'>
            <div className="row gy-5">
              <div className='col-12'>
                <div>
                  <h2 className="body-heading pb-3 pt-2">{heading}</h2>
                  <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para}}></div>
                  <br />
                  <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para2}}></div>
                  {
                    img &&
                    <img className="img-fluid mb-4" src={img} />
                  }
    
                  {heading2 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading2}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para3}}></div>
                    </>
                  )}
                  {heading3 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading3}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para4}}></div>
                    </>
                  )}
                  {heading4 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading4}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para5}}></div>
                    </>
                  )}
                  {heading5 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading5}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para6}}></div>
                    </>
                  )}
                  {heading6 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading6}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para7}}></div>
                    </>
                  )}
                  {heading7 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading7}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para8}}></div>
                    </>
                  )}
                  {heading8 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading8}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para9}}></div>
                    </>
                  )}
                  {heading9 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading9}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para10}}></div>
                    </>
                  )}
                  {heading10 && (
                    <>
                      <h2 className="body-heading pb-3 pt-2">{heading10}</h2>
                      <div className='body-paragraph' dangerouslySetInnerHTML={{__html:para11}}></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default CaseStContent