import React from 'react'
import TextContent from '../components/TextContent/TextContent'
import Footer from '../components/Footer/Footer'
import TextListContent from '../components/TextListContent/TextListContent'
import LetsWorkTogether from "../components/letsWorkTogether/letsWorkTogether"
import SocialMediaHeroBanner from '../components/SocialMediaHeroBanner/SocialMediaHeroBanner'

function SocialMediaMarketing() {
    return (
      <div>
          <SocialMediaHeroBanner/>

          <TextContent
            img="/images/creatives/social-media-text-1.png"
            heading="Sparking Your "
            headingYellow="Social Presence"
            paragraph={"Ready to make waves in the social sphere? Look no further! Our extraordinary Social Media Marketing (SMM) services are your ticket to digital domination. From content creation to strategic advertising, we'll turbocharge your social presence and leave a lasting impression on your audience.\n With a blend of creativity and data-driven strategies, we'll turn your social media platforms into buzzing hubs of engagement and excitement. Whether you're aiming to skyrocket brand awareness, drive sales, or build a tribe of loyal followers, we've got the mojo to make it happen!"}
            btn={true}
            btnText="LET'S CONNECT"
            reverse={true}
          btnRef='/contact'
          />
  
          <TextListContent
            img="/images/creatives/social-media-text-2.png"
            heading="Igniting Your "
            headingYellow="Online Presence"
            paragraph="Our Social Media Marketing (SMM) services are designed to amplify your brand's visibility and engagement across social platforms. Benefit from:"
            btn={false}
            reverse={false}
            listData={[
              	"Strategic Content Creation",
 	              "Targeted Advertising",
 	              "Community Building",
 	              "Data-Driven Insights",
 	              "Brand Amplification",
            ]}
          />

          <LetsWorkTogether />

        <Footer/>
    </div>
  )
}

export default SocialMediaMarketing