import "./Testimonials.css"
import React,{useState} from "react";
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import TestimonialCard from "./TestimonialCard"

const testimonialData = [
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"joe doe",
    clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It h"
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"john don",
    clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It h"
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"berry gay",
    clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It h"
  },
  {
    clientImg:"/images/icons/testimonial-male.svg",
    clientName:"thomas smith",
    clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It h"
  },

] 

function Testimonials() {



  return (
    <div className="Testimonials-container py-5">
      <div className="container w-100 container-fluid py-4">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="font-bebus mb-2 color-yellow" style={{fontSize:"25px"}}>testimonials</p>
        <h3 className="body-heading font-bebus" style={{color:"white"}}>WHAT OUR CLIENT SAY</h3>
        

        <div className="card-list" style={{position:"relative"}}>

        <Swiper
        spaceBetween={0}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        autoplay={
          {
            delay:2000,
            disableOnInteraction:false
          }
        }
        modules={[Autoplay,Pagination]}
        pagination={{ clickable: true }}
        observer ={true}
        observeParents={true}
        parallax={true}
        breakpoints={{
            280: {
              slidesPerView: 1,
            },
            1450: {
              slidesPerView: 3,
            }
          }}
        className="testimonials-list py-5 mt-2"
        id="testimonial-cards-slider"
            >
              {
                testimonialData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        
        </div>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials