import React from 'react'
import "./Footer.css"

function Footer() {
  return (
    <div className='Footer-container pt-5'>
        <div className='container mt-5 pt-3'>
          <div className='text-content text-center d-flex flex-column align-items-center gap-4'>
          <img src='/images/icons/brand-logo-light.svg' alt='navigation bar logo'/>
            <p className='body-paragraph w-50'>
            Salman Baig, the creative genius at Creative Squad, turns ideas into digital gold. With him, your success is not just a goal; it's a guarantee! Let's rock the digital world together!
            </p>

            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
              <img className='social-icons' alt='social icon' src='/images/icons/fb-icon.svg'/>
              <img className='social-icons' alt='social icon' src='/images/icons/insta-icon.svg'/>
              <img className='social-icons' alt='social icon' src='/images/icons/twitter-icon.svg'/>
              <img className='social-icons' alt='social icon' src='/images/icons/linked-icon.svg'/>
            </div>

            <div className='line-seprator'></div>

            <div className='d-flex flex-column flex-lg-row align-items-center justify-content-between w-100 mb-3'>
              <p className='body-paragraph' style={{fontSize:"14px"}}>© 2024 Salman Baig | All rights reserved </p>

              <div className='d-flex align-items-center jusstify-content-center gap-3'>
                <img className='social-icons' alt='social icon' src='/images/icons/Icon-visa.png'/>
                <img className='social-icons' alt='social icon' src='/images/icons/Icon-stripe.png'/>
                <img className='social-icons' alt='social icon' src='/images/icons/Icon-paypal.png'/>
                <img className='social-icons' alt='social icon' src='/images/icons/Icon-mast.png'/>
                <img className='social-icons' alt='social icon' src='/images/icons/Icon-amazon-.png'/>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Footer