import React, { useEffect, useState } from 'react'
import "./BlogCard.css"
import { Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

function MainCard({
    img,
    date,
    title,
    content,
    link
}) {

    const navigate = useNavigate();

    return(
        <div>
            <img src={img} className='img-fluid w-100' alt='blog thumbnail'/>

            <p className='body-paragraph my-3 date d-flex align-items-center gap-2'>Dated <div style={{border:"1px solid black",width:"35px"}}></div> {date}</p>

            <h3 className='title font-manrope'>{title}</h3>

            <p className='body-paragraph'>{content}</p>

            <p className='anchor'
                onClick={()=>{navigate(link)}}
            > Read Article<img src='/images/icons/cs-arrow-right.svg' style={{width:"20px",height:"10px"}} alt='arrow icon'/></p>
        </div>
    )
}

function SecondaryCard({
    img,
    date,
    title
})
{
    console.log("second img",img);
    
    return(
        <div className='side-card'>
            <div className='container'>
                <div className='row mb-4 alig-items-center'>
                    <div className='col-5 img-container' style={{backgroundImage:`url(${img})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",borderRadius:"12px"}}>
                        {/* <img src={img} alt='blog thumbnail' className='img-fluid' /> */}
                    </div>

                    <div className='col-7'>
                        <p className='body-paragraph mb-3 date d-flex align-items-center gap-2'>Dated <div style={{border:"1px solid black",width:"35px"}}></div> {date}</p>
                        <h3 className='title font-manrope mb-0'>{title}</h3>
                    </div>

                </div>

            </div>
        </div>
    )
}

function BlogCard() {
    const navigate = useNavigate()
    const [mainBlogsData, setMainBlogsData] = useState();
    const [blogsData, setBlogsData] = useState();
  
    const [loading, setLoading] = useState(false);
  
    let blogAPICalledId = false;
    let allBlogsCalled = false;
  
    const getAllBlogs = async () => {
      if (allBlogsCalled) return;
      allBlogsCalled = true;
  
      setLoading(true);
      
      axios
        .get(`/blogs`, {})
        .then((res) => {
          if (res.data.status === "success") {
            let Updated_recent_blogs = [];
            
            let mainBlog = res.data.data.slice(0,1);
            setMainBlogsData(mainBlog[0])


            let blog = res.data.data.slice(1, 4);
            setBlogsData(blog);
  
            
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setBlogsData(null)
          setLoading(false);
        });
    };
  
    useEffect(() => {
      getAllBlogs();
    }, []);

    const convertToSlug = (str) => {
        return str.toLowerCase().replace(/\s+/g, "-");
      };
    
      const redirectUserToBlog = (id) => {
        if (!id) return;
        let slug = convertToSlug(id);
    
        navigate(`/blog/${slug}`);
      };

  return (
    <div className='BlogCard-container py-5 my-4'>
        <div className='container my-3'>
            <div className='main'>
                <div className='text-content'>
                    <div className='row gy-3 align-items-center'>
                        <div className='col-lg-6 text-lg-start text-center'>
                            <h3 className='body-heading font-bebus color-blue' >BLOGS & <span className='color-yellow'>ARTICLES</span></h3>
                            <p className='body-paragraph font-manrope'>Fuel Your Mind with Our Digital Delights: Explore Our Blogs and Articles!</p>
                        </div>

                        <div className='col-lg-6 text-center text-lg-end my-3 my-lg-0'>
                            <button className='genral-btn btn-blue' onClick={()=>{navigate("/blog")}}>MY BLOGS</button>
                        </div>
                    </div>
                </div>
                {
                    loading ?
                        <div
                            style={{ width: "100%", height: "100vh" }}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <Spinner
                            style={{ color: "black", width: "120px", height: "120px" }}
                            />
                        </div>
                    :
                        <div className='row gy-3 mt-4'>
                            {
                                mainBlogsData &&
                                <div className='col-lg-6'>
                                    <MainCard
                                        img={mainBlogsData.blog_image}
                                        date={mainBlogsData.published_date}
                                        title={mainBlogsData.title}
                                        content={mainBlogsData.brief_paragraph}
                                        link={mainBlogsData.slug_url}
                                        redirectionFuntion={redirectUserToBlog}
                                    />

                                </div>
                            }

                            {
                                blogsData && 
                                <div className='col-lg-6'>
                                    <div className='d-flex flex-column'>
                                        {
                                            blogsData.map((blog)=>{
                                                return(
                                                    <SecondaryCard
                                                        img={blog.blog_image}
                                                        date={blog.published_date}
                                                        title={blog.title}
                                                        link={blog.slug_url}
                                                        redirectionFuntion={redirectUserToBlog}
                                                    />
                                                )
                                            })
                                        }
                                        
                                    </div>  
                                </div>
                            }

                        </div>
                }

                {/* <div className='row gy-3'>
                    <div className='col-lg-6'>
                        <MainCard
                            img="/images/creatives/blog-img-1.png"
                            date="Jan 30,2024"
                            title="What is the right design tool to choose in 2024?"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elitl lacus enim"
                            link="#"
                        />

                    </div>

                    <div className='col-lg-6'>
                        <div className='d-flex flex-column '>
                            <SecondaryCard
                                img="/images/creatives/side-blog-1.png"
                                date="Jan 30,2024"
                                title="Font sizes in Brandingdesign: The completeguide to follow"
                            />
                            <SecondaryCard
                                img="/images/creatives/side-blog-2.png"
                                date="Jan 30,2024"
                                title="Font sizes in Brandingdesign: The completeguide to follow"
                            />
                            <SecondaryCard
                                img="/images/creatives/side-blog-3.png"
                                date="Jan 30,2024"
                                title="Font sizes in Brandingdesign: The completeguide to follow"
                            />
                        
                        </div>  
                    </div>

                </div> */}


            </div>


        </div>

    </div>
  )
}

export default BlogCard