import React,{useState} from 'react'
import "./NavLinks.css"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';


function NavLinks() {
  const [innerServiceDrop , setInnerServiceDrop] = useState(false)
    const dispatch = useDispatch()

    const toogleBannerState = () => {
        dispatch({ type: "TOGGLE_OPEN", payload: {} });
      }
  return (
    <div className="NavLinks-container w-100">
      <ul className="w-100">
        <li className="py-3 text-start">
          <Link className="nav-links font-bebus mb-0" to={"/"} onClick={toogleBannerState}>
            HOME
          </Link>
        </li>
        <li className="py-3 text-start">
          <Link
            className="nav-links font-bebus mb-0"
            to={"/about"}
            onClick={toogleBannerState}
          >
            ABOUT
          </Link>
        </li>
        <li className="py-3 text-start ">
          <div 
            className='d-flex '
            onClick={() => {
              setInnerServiceDrop(!innerServiceDrop);
            }}
          >
            <p
              className="nav-links font-bebus mb-0"
            >
              SERVICES
            </p>
            <img
              src={innerServiceDrop ?"/images/icons/sub-icon.svg" :"/images/icons/add-icon.svg"}
              alt="add icon"
              style={{ marginLeft: "auto" }}
              
            />
          </div>

          <div
            className={`slide ${innerServiceDrop ? "slide-in-cus" : "slide-out-cus"} `}
            style={{ paddingLeft: "0px" }}
          >
            <div className='container-fluid'>
              <ul className="w-100 row p-0">
                <li className="py-3 text-start col-lg-6">
                  <Link
                    className="nav-links font-bebus mb-0"
                    to={"/user-interface"}
                    onClick={toogleBannerState}
                  >
                    UI/UX
                  </Link>
                </li>
                <li className="py-3 text-start col-lg-6">
                  <Link
                    className="nav-links font-bebus mb-0"
                    to={"/search-engine-optimization"}
                    onClick={toogleBannerState}
                  >
                    SEO
                  </Link>
                </li>
                <li className="py-3 text-start col-lg-6">
                  <Link
                    className="nav-links font-bebus mb-0"
                    to={"/google-ads"}
                    onClick={toogleBannerState}
                  >
                    Google Ads
                  </Link>
                </li>
                <li className="py-3 text-start col-lg-6">
                  <Link
                    className="nav-links font-bebus mb-0"
                    to={"/social-media-marketing"}
                    onClick={toogleBannerState}
                  >
                    Social Media Marketing
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>
        </li>

        <li className="py-3 text-start">
          <Link className="nav-links font-bebus mb-0" onClick={toogleBannerState} to={"/blog"}>
            BLOGS
          </Link>
        </li>
        <li className="py-3 text-start">
          <Link className="nav-links font-bebus mb-0" onClick={toogleBannerState} to={"/case-study"}>
            CASE STUDIES
          </Link>
        </li>
        <li className="py-3 text-start">
          <Link className="nav-links font-bebus mb-0" onClick={toogleBannerState} to={"/contact"}>
            CONTACT
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NavLinks