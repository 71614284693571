import React from 'react'
import "./AboutText.css"

function AboutText() {
  return (
    <div className='AboutText-container py-5'>
      <div className='container my-4'>
        <div className='text-content row align-items-center align-items-lg-start pt-lg-4 pt-0'>
          <div className='col-lg-4 text-center text-lg-end heading'>
            <h3 className='hero-heading-font-size font-bebus color-white mb-1 w-100 heading-large'>SALMAN</h3>
            <h3 className='hero-heading-font-size font-bebus color-yellow'>BAIG</h3>
          </div>
          <div className='col-lg-4'>
            <p className='body-paragraph color-white'>Salman Baig isn't just a name; he's a force of innovation, a beacon of creativity in the digital society. As the mastermind behind Creative Squad, he's redefining the game, one pixel at a time. With a finger on the pulse of the ever-evolving digital policies, Salman doesn't just meet expectations; he exceeds them, crafting masterpieces that resonate with audiences far and wide.</p>
          </div>
          <div className='col-lg-4'>
            <p className=' body-paragraph color-white'>Salman's journey is one of passion and dedication, fueled by a relentless pursuit of excellence. From humble beginnings to becoming the driving force behind a leading digital solutions company, his story is a testament to the power of vision and perseverance. With Salman, Creative Squad isn't just a company; it's a promise—a promise of unparalleled creativity, unwavering commitment, and boundless success. So, if you're ready to set new goals like never before, you better believe Salman Baig is the man for the job.</p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default AboutText