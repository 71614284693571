import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop"
import "./bootstrap.min.css"
import { Provider } from 'react-redux';
import store from "./store/store"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop/>
        <App />
    </BrowserRouter> 
    </Provider>
);

reportWebVitals();
