import React from 'react'
import "./PartnerCompanies.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const companyData =[
    "/images/icons/partner-logo-1.png",
    "/images/icons/partner-logo-1.png",
    "/images/icons/partner-logo-1.png",
    "/images/icons/partner-logo-1.png",
    "/images/icons/partner-logo-1.png",

]

function PartnerCompanies() {
  return (
    <div className='PartnerCompanies-container py-5'>
        <div className='container my-5'>
            <div className='text-content text-center'>
                <h3 className='main-heading font-bebus hero-heading-font-size'>
                  
                    <span className='color-yellow '><span className='color-blue'>You've got </span>dreams,</span>
                    <br></br>
                    {/* <span className='color-yellow'> </span>
                    <br></br> */}
                    <span className='color-yellow '><span className='color-blue '>we've got </span>the skills! </span>
                </h3>
                <p className='font-bebus my-3 color-blue' style={{fontSize:"31px",fontWeight:"bold"}}>TRUSTED BY INDUSTRY LEADERS</p>
            </div>

            <div className='logo-caruosel my-5'>
                <Swiper
                    spaceBetween={10}
                    autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        },
                        550: {
                        slidesPerView: 2,
                        },
                        800: {
                        slidesPerView: 3,
                        },
                        1000: {
                        slidesPerView: 4,
                        },
                        1400:{
                            slidesPerView:5
                        }
                    }}
                    className={`mx-auto py-3`}
                    >
                        {
                            companyData.map((ele)=>{
                                return(
                                    <SwiperSlide>
                                        <img src={ele}  alt="partner company logo" />
                                    </SwiperSlide>
                                )
                            })
                        }
                        
                </Swiper>
            </div>
        </div>
    </div>
  )
}

export default PartnerCompanies