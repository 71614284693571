import React, { useEffect } from 'react'
import CaseStudyInnerHeroBanner from '../components/CaseStudyInnerHeroBanner/CaseStudyInnerHeroBanner'
import HeadingContent from '../components/HeadingContent/HeadingContent'
import HeadingListContent from '../components/HeadingListContent/HeadingListContent'
import FollowMe from '../components/FollowMe/FollowMe'
import Footer from '../components/Footer/Footer'
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { listCaseStudyDetails } from "../store/actions/caseStudyActions";
import { Spinner } from 'react-bootstrap';
import CaseStContent from "../components/CaseStContent/CaseStContent"

function CaseStudyInner() {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { error, loading, caseStudy } = caseStudyDetails;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_DETAILS_RESET" });
    dispatch(listCaseStudyDetails(id));
  }, [dispatch, id]);
  return (
    <>
     {loading || !caseStudy ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Spinner
            style={{
              color: "black",
              width: "120px",
              height: "120px",
              alignSelf: "center",
              textAlign: "center",
            }}
          />
        </div>
      ) : error ? (
        <p className="para_main" style={{ color: "red" }}>
          {error}
        </p>
      ) : (
        caseStudy &&
        !loading &&
        !error && (
          <>
            <div >
                <CaseStudyInnerHeroBanner
                   Heading={caseStudy.heroHeading}
                   catagory={caseStudy.category1}
                   clientName={caseStudy.clientName}
                   img={caseStudy.bannerImage}
                />
                 <CaseStContent
                heading={caseStudy.sec1Heading}
                para={caseStudy.sec1Para}
                para2={caseStudy.sec1Para2}
                img={caseStudy.sec1Img}
                heading2={caseStudy.sec2Heading}
                para3={caseStudy.sec2Para}
                heading3={caseStudy.sec3Heading}
                para4={caseStudy.sec3Para}
                heading4={caseStudy.sec4Heading}
                para5={caseStudy.sec4Para}
                heading5={caseStudy.sec5Heading}
                para6={caseStudy.sec5Para}
                heading6={caseStudy.sec6Heading}
                para7={caseStudy.sec6Para}
                heading7={caseStudy.sec7Heading}
                para8={caseStudy.sec7Para}
                heading8={caseStudy.sec8Heading}
                para9={caseStudy.sec8Para}
                heading9={caseStudy.sec9Heading}
                para10={caseStudy.sec9Para}
                heading10={caseStudy.sec10Heading}
                para11={caseStudy.sec10Para}
              />

              {/* <HeadingContent
                heading="About the "
                headingYellow="project"
                paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the"
                btn={false}
                reverse={false}

              />


              <HeadingListContent
                heading="Project "
                headingYellow="execution"
                paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the"
                postparagraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit diam quam nisi ut pellentesque nec cursus habitant nec lobortis ac placerat non, urna tempus luctus lobortis sed dui nisl, non mattis lorem aliquam congue turpis condimentum massa viverra pellentesque morbi."
                btn={false}
                reverse={false}
                listData={[
                  "galley of type and scrambled it to make a type specimen book.",
                  "galley of type and scrambled it to make a type specimen book.",
                  "galley of type and scrambled it to make a type specimen book.",
                  "galley of type and scrambled it to make a type specimen book.",
                ]}
              />

              <div className='container'>
                <div className='line-seprator-casest'></div>
              </div>

              <HeadingListContent
                heading="Project "
                headingYellow=" gallery"
                paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit diam quam nisi ut pellentesque nec cursus habitant nec lobortis ac placerat non, urna tempus luctus lobortis sed dui nisl, non mattis lorem aliquam congue turpis condimentum massa viverra pellentesque morbi."
                postparagraph=""
                btn={false}
                reverse={false}
                listData={null}
                img={"/images/creatives/case-inner-content-img.png"}
              /> */}
               </div>
          </>
        )
      )}

       <FollowMe/>

       <Footer/>
    </>
  )
}

export default CaseStudyInner