import { thunk } from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import { configureStore } from "@reduxjs/toolkit";
import { userLoginReducer } from "./reducers/userReducers";
import {
    caseStudyCreateReducer,
    caseStudyDeleteReducer,
    caseStudyDetailsReducer,
    caseStudyUpdateReducer,
    caseStudyListReducer
  } from "./reducers/caseStudyReducers";


const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;


const initialState = {
    bannerOpen: false,
    userLogin: { userInfo: userInfoFromStorage },
}

// Action types
const TOGGLE_OPEN = 'TOGGLE_OPEN';



// Reducer function
const bannerReducer = (state = true, action) => {
    switch (action.type) {
        case TOGGLE_OPEN:
            return !state;
        default:
            return state;
    }
};

// Action creator
export const toggleOpen = () => ({
    type: TOGGLE_OPEN
});

const allReducer = combineReducers({ 
    caseStudyList: caseStudyListReducer,
    caseStudyDetails: caseStudyDetailsReducer,
    caseStudyDelete: caseStudyDeleteReducer,
    caseStudyCreate: caseStudyCreateReducer,
    caseStudyUpdate: caseStudyUpdateReducer,
    userLogin: userLoginReducer,
    banner: bannerReducer 
});


const middleware = [thunk];

const store = createStore(
    allReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
