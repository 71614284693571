import React from 'react'
import "./Header.css"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


function Header({
  logoLight=false,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <div className='Header-container'>
        <div className='container'>
            <div className='main d-flex justify-content-between align-items-center py-4'>
                <img 
                  src={logoLight ? `/images/icons/brand-logo-light.svg`:`/images/icons/nav-logo.svg`}
                  className='nav-logo'
                  alt='navigation bar logo'
                  onClick={()=>{navigate("/")}}
                />

                <img 
                src={logoLight ? `/images/icons/hamburger-icon-light.png`:`/images/icons/hamburger-icon.svg`} 
                alt='menu icon' 
                onClick={()=>{
                  dispatch({type:"TOGGLE_OPEN",payload:{}})
                 
                }}/>
            </div>

        </div>
    </div>

  )
}

export default Header