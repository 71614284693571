import React from 'react'
import UiHeroBanner from '../components/UIHeroBanner/UiHeroBanner'
import TextContent from '../components/TextContent/TextContent'
import Footer from '../components/Footer/Footer'
import TextListContent from '../components/TextListContent/TextListContent'
import LetsWorkTogether from "../components/letsWorkTogether/letsWorkTogether"

function Ui() {
    return (
      <div>
        <UiHeroBanner />

        <TextContent
          img="/images/creatives/ui-text-1.png"
          heading="Crafting "
          headingYellow="Engaging UI/UX"
          paragraph="This is where Design Meets Delight! Be a part of seamless user experiences and visually stunning interfaces with us. At Creative Squad, we blend creativity with functionality to create digital experiences that captivate and convert. From scratch ideas to pixel-perfect designs, our UI/UX services are customized to compliment your brand and leave a lasting impression. Let's collaborate and bring your digital vision to life with our expert UI/UX craftsmanship!"
          btn={true}
          btnText="LET'S CONNECT"
          reverse={true}
          btnRef='/contact'
        />

        <TextListContent
          img="/images/creatives/ui-text-2.png"
          heading="RESPONSIVE "
          headingYellow="DESIGN"
          paragraph="Seamlessly Adapt to Every Device! Our responsive design services ensure your website looks flawless on any screen size, from smartphones to desktops. With fluid layouts and flexible elements, we guarantee a consistent user experience across all devices. Benefit from:"
          btn={false}
          reverse={false}
          listData={[
            "Improved User Experience",
            "Increased Accessibility",
            "Better SEO Performance",
            "Cost-Effective Solution",
            "Future-Proofing Your Site",
          ]}
        />

        <LetsWorkTogether />

        <Footer />
      </div>
    );
}

export default Ui