import React from 'react'
import AboutHeroBanner from '../components/AboutHeroBanner/AboutHeroBanner'
import AboutText from '../components/AboutText/AboutText'
import MyAcievment from '../components/MyAcievment/MyAcievment'
import PastExperience from '../components/PastExperience/PastExperience'
import Footer from '../components/Footer/Footer'

function About() {
  return (
    <div>
        <AboutHeroBanner/>

        <AboutText/>

        <MyAcievment/>

        <PastExperience/>

        <Footer/>
    </div>
  )
}

export default About