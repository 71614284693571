import React from 'react'
import HomeHeroBanner from '../components/HomeHeroBanner/HomeHeroBanner'
import TextContent from '../components/TextContent/TextContent'
import MyServices from '../components/MyServices/MyServices'
import PartnerCompanies from '../components/PartnerCompanies/PartnerCompanies'
import CaseStudyCard from '../components/CaseStudyCard/CaseStudyCard'
import BlogCard from '../components/BlogCard/BlogCard'
import Testimonials from '../components/Testimonials/Testimonials'
import FollowMyWork from '../components/FollowMyWork/FollowMyWork'
import Footer from '../components/Footer/Footer'

function Home() {
  return (
    <div style={{backgroundColor:" #f0f2f5"}}>
        <HomeHeroBanner/>

        <TextContent
          img="/images/creatives/home-text-1.jpg"
          heading="WHo IS "
          headingYellow="SALMAN BAIG?"
          paragraph="Salman Baig is the visionary behind Creative Squad, a leading digital solutions company based in Canada. With a knack for transforming ideas into successful digital realities, Salman brings a unique blend of creativity and expertise to every project. If you envision your brand reaching new heights of success, you better call Salman Baig."
          btn={true}
          btnText="MORE ABOUT ME"
          btnRef='/about'
        />

        <MyServices/>

        <TextContent
          img="/images/creatives/home-text-2.png"
          heading="My Portfolio"
          headingYellow=""
          paragraph="Step into the wild and wondrous world of Salman Baig and his brainchild, Creative Squad! Years of digital wizardry, seasoned with a sprinkle of innovation and a dash of genius. From turning dreams into digital delights to wowing clients with the magical touch, we've been there, done that, and rocked the socks off! Go through our portfolio and see for yourself!"
          btn={true}
          btnText="MY AGENCY"
          outerLink={true}
          btnRef='https://www.creativesquad.ca/'
        />

        <PartnerCompanies/>

        <CaseStudyCard/>

        <BlogCard/>

        <Testimonials/>

        <FollowMyWork/>

        <Footer/>
    </div>
  )
}

export default Home