import React from 'react'
import "./letsWorkTogether.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useNavigate } from 'react-router-dom';



function LetsWorkTogether() {
    const navigate =useNavigate()
    return (
        <div className='letwork-container py-5'>
            <div className='container my-5'>
                <div className='text-content text-center'>
                    <h3 className='main-heading font-bebus hero-heading-font-size font-bold'>
                        <span className='color-blue'> Let's Team Up</span>
                        <br></br>
                        <span className='color-yellow heading-large'>and Create Digital Magic!</span>
                       
                    </h3>
                    

                    <button className='genral-btn btn-blue mt-4' onClick={()=>{navigate("/contact")}}>Let's Chat</button>
                </div>
    
                
            </div>
        </div>
      )
}

export default LetsWorkTogether