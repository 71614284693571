import React from 'react'
import "./ContactLocation.css"

function ContactLocation({
    img="",
    heading="",
    headingYellow,
    paragraph,
    btn=false,
    btnText,
    reverse=false
}) {
  return (
    <div className='ContactLocation-container py-5'>
        <div className='container my-4'>
            <div className={`main row align-items-center gy-5 ${reverse && "flex-row-reverse"}`}>
                <div className='img-container col-lg-6 px-4 text-center text-lg-start'>
                    <img src={img} className='img-fluid' alt='text content ' />
                </div>

                <div className='text-content col-lg-6 text-center text-lg-start'>
                    <h3 className='font-bebus body-heading color-white' style={{fontSize:"65px"}}>
                        {heading}{headingYellow&&<span className='color-yellow title' >{headingYellow}</span>}
                    </h3>
                    

                    <div className='location-container' >
                        <div className='d-flex align-items-center justify-content-center justify-content-lg-start gap-3 my-4'>
                            <img src='/images/icons/contact-icon-address.svg' alt='address icon' className='social-icon'/>
                            <p className='body-paragraph color-white mb-0'>456 Kangaroo Street, Sydney</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center justify-content-lg-start gap-3 my-4'>
                            <img src='/images/icons/contact-icon-phone.svg' alt='address icon' className='social-icon'/>
                            <p className='body-paragraph color-white mb-0'>+1-647-860-5083</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center justify-content-lg-start gap-3 my-4'>
                            <img src='/images/icons/contact-icon-mail.svg' alt='address icon' className='social-icon'/>
                            <p className='body-paragraph color-white mb-0'>hello@salmanbaig.me</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactLocation