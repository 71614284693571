import React from 'react'
import CaseStudiesList from '../components/CaseStudiesList/CaseStudiesList'
import Footer from '../components/Footer/Footer'
import LetsWorkTogether from "../components/letsWorkTogether/letsWorkTogether"
import CaseStudyHeroBanner from '../components/CaseStudyHeroBanner/CaseStudyHeroBanner'

function CaseStudies() {
  return (
    <>

    <CaseStudyHeroBanner/>

        <CaseStudiesList/>

        <LetsWorkTogether />

        <Footer/>
    </>
  )
}

export default CaseStudies