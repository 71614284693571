import React from 'react'

function ReviewBox() {
  return (
    <div className='ReviewBox-container  py-5'>
        <div className='container my-4'>
            <div className='row align-items-center gy-3'>
                <div className='col-xxl-3 col-lg-4 text-center text-lg-start'>
                    <img src='/images/creatives/review-person.png' alt='person '/>
                </div>
                <div className='col-xxl-9 col-lg-7 '>
                    <div className='text-content'>
                        <h3 className='color-blue body-heading mb-0'>SALMAN <span className='color-yellow'>BAIG</span></h3>
                        <p className='body-paragraph mt-1 mb-0'>Salman Baig is the visionary behind Creative Squad, a leading digital solutions company based in Canada.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ReviewBox