import React from 'react'
import ContactHeroBanner from '../components/ContactHeroBanner/ContactHeroBanner'
import ContactFormSection from '../components/ContactFormSection/ContactFormSection'
import ContactLocation from '../components/ContactLocation/ContactLocation'
import FollowMyWork from '../components/FollowMyWork/FollowMyWork'
import Footer from '../components/Footer/Footer'

function Contact() {
  return (
    <>
        <ContactHeroBanner/>

        <ContactFormSection/>

        <ContactLocation
            img="/images/creatives/contact-loc-section.png"
            heading=""
            headingYellow="Ontario, Canada"
            btn={false}
        />

        <FollowMyWork/>

        <Footer/>
    </>
  )
}

export default Contact