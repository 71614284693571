import React, { useState } from 'react'
import "./SubscribeNewsletter.css"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';




function SubscribeNewsletter() {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-newsletter", {
          email: email,
          formType: "Newsletter",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Newsletter Subscribed Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Email failed to send, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
          })
        }
  return (
    <div className='SubscribeNewsletter-container py-5'>
        
            <div className='follow-container my-3 '>
                <div className='container'>

                <div className='row my-4'>
                    <div className='col-lg-6'>
                        <div className='follow-text'>
                            <h3 className='body-heading color-white'>Subscribe to our <span style={{color:"#040404"}}>newsletter</span></h3>
                            <p className='body-paragraph color-white my-3'>Stay ahead of the curve with our latest insights and updates delivered straight to your inbox. Subscribe now and join our community of digital enthusiasts!</p>
                            <form className='d-flex align-items-center jusstify-content-center gap-3 mt-4 inp-container' onSubmit={formSubmitHandler}>
                                <input
                                    type='email'
                                    placeholder='enter your email'
                                    className='none-input w-100'
                                    value={email}
                                    maxLength="36"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button className='none-button color-white d-flex align-items-center gap-2'>SUBSCRIBE
                                    <img src='/images/icons/arrow-white.png' />
                                    {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                                </button>
                            </form>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <img src='/images/creatives/bell-icon.png' className='medal-icon' alt='medal icon'/>

                    </div>

                </div>
                </div>

            </div>
    </div>

  )
}

export default SubscribeNewsletter