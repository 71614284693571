import React from 'react'
import "./PastExperience.css"
import { useNavigate } from 'react-router-dom'

const cardData = [
    {
        title:"Creative Squad Inc.",
        position:"CEO/Co-Founder",
        startDate:"Feb 2020",
        endDate:"Present",
        content:"As the visionary leader of Creative Squad Inc., Salman Baig drives innovation and success, transforming digital dreams into reality for brands far and wide."
    },
    {
        title:"BNI Escarpment Entrepreneurs",
        position:"Digital Media Consultant",
        startDate:"Nov 2023",
        endDate:"Present",
        content:"At BNI, Salman supports local businesses with Creative Squad’s top-notch digital media solutions, fueling growth and community connections in Halton Region & GTA."
    },
    {
        title:"GoGeekz Inc.",
        position:"Digital Media Sales Manager",
        startDate:"Nov 2022",
        endDate:"April 2023",
        content:"At GoGeekz Inc., Salman mastered the art of the full sales cycle, from cold calls to closing deals, crafting strategies to overcome client challenges seamlessly."
    },
    {
        title:"Accenture - IT Company",
        position:"Digital Media Sales Executive",
        startDate:"June 2021",
        endDate:"Sept 2022",
        content:"As a Sales Executive at Accenture, Salman excelled in prospecting and closing sales, driving business growth with his expertise in the full sales cycle."
    },
]


function ExpCard({
    title="",
    position="",
    startDate="",
    endDate="",
    content=""
}) {
    return(
        <div className='exp-card-container container px-2 py-5' style={{height:"100%"}}>
            <div className='row gy-3 '>
                <div className='col-xl-6'>
                    <h5 className='title font-bebus color-yellow'>{title}</h5>
                    <p className='body-paragraph '>{position}</p>
                </div>

                <div className='col-xl-6'>
                    <p className='body-paragraph d-flex align-items-center gap-2'>
                            {startDate}  <div style={{border:"1px solid white",width:"35px"}}></div>  {endDate}
                    </p>

                </div>
            </div>

            <p className='body-paragraph pt-3'>{content}</p>
        </div>
    )
}

function PastExperience() {
    const navigate =useNavigate()
  return (
    <div className='PastExperience-container py-5'>
        <div className='container my-4'>
            <div className='text-content pb-5'>
                <div className='row gy-3 align-items-center'>
                    <div className='col-lg-6'>
                        <h3 className='body-heading font-bebus color-white' >TAKE A LOOK AT MY <br></br> <span className='color-yellow'>PAST EXPERIENCES</span></h3>
                       
                    </div>

                    <div className='col-lg-6 text-start text-lg-end'>
                        <button className='genral-btn btn-yellow' onClick={()=>{navigate("/contact")}}>GET IN TOUCH</button>
                    </div>
                </div>

            </div>

            <div className='row'>
                {
                    cardData.map((ele)=>{
                        return(
                            <div className='col-lg-6 pt-2'>

                                <ExpCard
                                    title={ele.title}
                                    position={ele.position}
                                    startDate={ele.startDate}
                                    endDate={ele.endDate}
                                    content={ele.content}
                                />

                            </div>
                        )
                    })
                }

            </div>

        </div>
            <div className='follow-container my-3 '>
                <div className='container'>

                <div className='row my-4'>
                    <div className='col-lg-6'>
                        <div className='follow-text'>
                            <h3 className='body-heading color-white'>Join the PartyE</h3>
                            <p className='body-paragraph color-white my-3'>Connect with Me on Social Media and Let's Make Some Noise!</p>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-4'>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-1.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-2.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-3.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-4.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-5.svg'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/follow-icon-6.svg'/>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <img src='/images/creatives/medal-icon.png' className='medal-icon' alt='medal icon'/>

                    </div>

                </div>
                </div>

            </div>
    </div>

  )
}

export default PastExperience