import React,{useState,useEffect} from 'react'
import "./CaseStudiesList.css"
import { useDispatch,useSelector } from 'react-redux';
import { listCaseStudy } from "../../store/actions/caseStudyActions";
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const caseStudyData = [
    {
      bannerImage:"./images/creatives/case-st-card-1.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      bannerImage:"./images/creatives/case-st-card-2.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      bannerImage:"./images/creatives/case-st-card-3.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      bannerImage:"./images/creatives/case-st-card-4.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      bannerImage:"./images/creatives/case-st-card-1.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      bannerImage:"./images/creatives/case-st-card-2.png",
      heroHeading:"Brand Identity for Kimshi",
        anchor:"/case-study-inner",
        content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
]

function CaseStudiesList() {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [id, setId] = useState(false);
    const [readmore, setReadmore] = useState(false);
  
    const caseStudyList = useSelector((state) => state.caseStudyList);
    const { loading, error, caseStudies } = caseStudyList;
  
    // const filtered =
    //   caseStudies && !readmore
    //     ? caseStudies.filter((item, index) => index < 6)
    //     : caseStudies && caseStudies;
  
    // const categorized =
    //   caseStudies &&
    //   caseStudies.filter((item) => item.category1 === id).length > 0
    //     ? caseStudies.filter((item) => item.category1 === id)
    //     : caseStudies.filter((item) => item.category2 === id).length > 0
    //     ? caseStudies.filter((item) => item.category2 === id)
    //     : caseStudies.filter((item) => item.category3 === id).length > 0
    //     ? caseStudies.filter((item) => item.category3 === id)
    //     : caseStudies.filter((item) => item.category4 === id).length > 0
    //     ? caseStudies.filter((item) => item.category4 === id)
    //     : false;
  
    useEffect(() => {
      dispatch(listCaseStudy());
      
    }, []);

  return (
    <div className='CaseStudiesList-container py-5'>
        <div className='container my-4 my-xxl-5'>
            <div className='row'>

            {loading ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                style={{
                  color: "white",
                  width: "120px",
                  height: "120px",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              />
            </span>
          ) : error ? (
            <p className="para_main" style={{ color: "red" }}>
              {error}
            </p>
          ) :
          caseStudies ? (
            caseStudies.map((item,ind) => (
                <div className={`col-lg-6 px-3 ${(ind %2 !==0) && "second"} px-lg-5 px-1`}
                    
                >
                    <div className='case-st-card'>
                        <img src={item.bannerImage} className='img-fluid card-img mb-4'/>
                        <div className='d-flex align-items-center justify-content-between my-4 mb-2'>
                            <h5 className='body-heading mb-0 color-yellow' style={{fontSize:"24px"}}>{item.heroHeading}</h5>
                            <div className='d-flex align-items-center gap-2'
                                 onClick={()=>{navigate(`/casestudy/${item._id}`)}}
                               >
                                   <p className='body-paragraph mb-0 case-st-card-anchor ms-3' style={{textWrap:"nowrap"}}>Read More</p>
                                   <img src='./images/icons/case-st-card-icon.svg' alt="arrow icon" className='case-st-card-icon'/>
                               </div>
                        </div>
                        <h6 className='sub-heading color-blue' >{item.content}</h6>
                    </div>
                </div>
            ))
          ) : (
            <h6
              style={{
                color: "red",
                textAlign: "center",
                paddingTop: "50px",
                textTransform: "none",
              }}
              className="heading_main"
            >
              Sorry, No Matching Items Found
            </h6>
          )}
                {/* {
                    caseStudyData.map((ele,ind)=>{
                        return(
                            <div className={`col-lg-6 px-3 ${(ind %2 !==0) && "second"} px-lg-5 px-1 mb-xxl-5 mb-3`}>
                                <div className='case-st-card'>
                                    <img src={ele.bannerImage} className='img-fluid card-img mb-4'/>
                                    <div className='d-flex align-items-center justify-content-between my-4 mb-2'>
                                        <h5 className='body-heading mb-0 color-yellow' style={{fontSize:"24px"}}>{ele.heroHeading}</h5>
                                        <div className='d-flex align-items-center gap-2'
                                          onClick={()=>{
                                            navigate(ele.anchor)
                                          }}
                                        >
                                            <p className='body-paragraph mb-0 case-st-card-anchor'>Read More</p>
                                            <img src='./images/icons/case-st-card-icon.svg' alt="arrow icon" className='case-st-card-icon'/>
                                        </div>
                                    </div>
                                    <p className='sub-heading color-white case-card-content' >{ele.content}</p>
                                </div>
                            </div>
                        )
                    })
                } */}
                {/* <div className='col-lg-6 px-3'>
                    <div className='case-st-card'>
                        <img src='./images/creatives/case-st-card-1.png' className='img-fluid'/>
                        <div className='d-flex align-items-center justify-content-between my-3'>
                            <h5 className='body-heading mb-0' style={{fontSize:"24px"}}>Blue Fysh (Brand Name)</h5>
                            <div className='d-flex align-items-center gap-2'>
                                <p className='body-paragraph mb-0 case-st-card-anchor' style={{textDecoration:"underline"}}>View Case Study</p>
                                <img src='./images/icons/case-st-card-icon.svg' className='case-st-card-icon'/>
                            </div>
                        </div>
                        <h6 className='sub-heading color-blue' >Digital Signage</h6>
                    </div>
                </div>
                <div className='col-lg-6 px-3 second'>
                    <div className='case-st-card'>
                        <img src='./images/creatives/case-st-card-1.png' className='img-fluid'/>
                        <div className='d-flex align-items-center justify-content-between my-3'>
                            <h5 className='body-heading mb-0' style={{fontSize:"24px"}}>Blue Fysh (Brand Name)</h5>
                            <div className='d-flex align-items-center gap-2'>
                                <p className='body-paragraph mb-0 case-st-card-anchor' style={{textDecoration:"underline"}}>View Case Study</p>
                                <img src='./images/icons/case-st-card-icon.svg' className='case-st-card-icon'/>
                            </div>
                        </div>
                        <h6 className='sub-heading color-blue' >Digital Signage</h6>
                    </div>
                </div> */}
               
            </div>
        </div>

    </div>
  )
}

export default CaseStudiesList