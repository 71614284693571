import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  listCaseStudyDetails,
  updateCaseStudy,
} from "../../store/actions/caseStudyActions";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";


const caseStudyCatagoryAr = [
  "Full-Service Equestrian Facility",
  "Health",
  "Branded Apparel",
  "Digital Solutions",
  "Battery Store",
  "Food",
  "Real Estate",
  "Immigration",
  "Personal Development",
  "Legal Services",
  "Vehicle Repair",
  "Accounting and Tax Services",
  "Event Photography",
  "Cleaning & Linen Services",
  "Event Planning",
  "Home Staging Services",
  "Salon and Barber Supplies",
  "Branded Ladies Clothing"
]

const CaseStudyEditScreen = () => {
  const params = useParams();
  const caseStudyId = params.id;
  const dispatch = useDispatch();
  const history = useNavigate();

  const [heroHeading, setHeroHeading] = useState("");
  const [clientName, setClientName] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [category3, setCategory3] = useState("");
  const [category4, setCategory4] = useState("");
  const [template, setTemplate] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1Para, setSec1Para] = useState("");
  const [sec1Para2, setSec1Para2] = useState("");
  const [sec1Img, setSec1Img] = useState("");
  const [content, setContent] = useState([]);
  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Para, setSec2Para] = useState("");
  const [sec3Heading, setSec3Heading] = useState("");
  const [sec3Para, setSec3Para] = useState("");
  const [sec4Heading, setSec4Heading] = useState("");
  const [sec4Para, setSec4Para] = useState("");
  const [sec5Heading, setSec5Heading] = useState("");
  const [sec5Para, setSec5Para] = useState("");
  const [sec6Heading, setSec6Heading] = useState("");
  const [sec6Para, setSec6Para] = useState("");
  const [sec7Heading, setSec7Heading] = useState("");
  const [sec7Para, setSec7Para] = useState("");
  const [sec8Heading, setSec8Heading] = useState("");
  const [sec8Para, setSec8Para] = useState("");
  const [sec9Heading, setSec9Heading] = useState("");
  const [sec9Para, setSec9Para] = useState("");
  const [sec10Heading, setSec10Heading] = useState("");
  const [sec10Para, setSec10Para] = useState("");

  const [uploading, setUploading] = useState(false);

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { loading, error, caseStudy } = caseStudyDetails;

  const caseStudyUpdate = useSelector((state) => state.caseStudyUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = caseStudyUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_UPDATE_RESET" });
    if (!userInfo || !userInfo.isAdmin) {
      history("/login");
    }
    if (successUpdate) {
      history("/admin/casestudy");
      window.location.reload();
    } else {
      if (!caseStudy.heroHeading || caseStudy._id !== caseStudyId) {
        dispatch(listCaseStudyDetails(caseStudyId));
      } else {
        setHeroHeading(caseStudy.heroHeading);
        setClientName(caseStudy.clientName);
        setBannerImage(caseStudy.bannerImage);
        setCategory1(caseStudy.category1);
        setCategory2(caseStudy.category2);
        setCategory3(caseStudy.category3);
        setCategory4(caseStudy.category4);
        setTemplate(caseStudy.template);
        setSec1Heading(caseStudy.sec1Heading);
        setSec1Para(caseStudy.sec1Para);
        setSec1Para2(caseStudy.sec1Para2);
        setSec1Img(caseStudy.sec1Img);
        setSec2Heading(caseStudy.sec2Heading);
        setSec2Para(caseStudy.sec2Para);
        setSec3Heading(caseStudy.sec3Heading);
        setSec3Para(caseStudy.sec3Para);
        setSec4Heading(caseStudy.sec4Heading);
        setSec4Para(caseStudy.sec4Para);
        setSec5Heading(caseStudy.sec5Heading);
        setSec5Para(caseStudy.sec5Para);
        setSec6Heading(caseStudy.sec6Heading);
        setSec6Para(caseStudy.sec6Para);
        setSec7Heading(caseStudy.sec7Heading);
        setSec7Para(caseStudy.sec7Para);
        setSec8Heading(caseStudy.sec8Heading);
        setSec8Para(caseStudy.sec8Para);
        setSec9Heading(caseStudy.sec9Heading);
        setSec9Para(caseStudy.sec9Para);
        setSec10Heading(caseStudy.sec10Heading);
        setSec10Para(caseStudy.sec10Para);
      }
    }
  }, [dispatch, history, caseStudy, caseStudyId, successUpdate, userInfo]);

  const uploadBannerHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setBannerImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg1Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setSec1Img(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const formSubmitHander = (event) => {
    event.preventDefault();
    dispatch(
      updateCaseStudy({
        _id: caseStudyId,
        heroHeading,
        clientName,
        bannerImage,
        category1,
        category2,
        category3,
        category4,
        template,
        sec1Heading,
        sec1Para,
        sec1Para2,
        sec1Img,
        sec2Heading,
        sec2Para,
        sec3Heading,
        sec3Para,
        sec4Heading,
        sec4Para,
        sec5Heading,
        sec5Para,
        sec6Heading,
        sec6Para,
        sec7Heading,
        sec7Para,
        sec8Heading,
        sec8Para,
        sec9Heading,
        sec9Para,
        sec10Heading,
        sec10Para,
      })
    );
  };
  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="pb-5 pt-5">
        <Container>
          <Link to="/admin/casestudy" className="btn btn-dark my-3">
            Go Back
          </Link>
          <h1>Edit Case Study</h1>
          {loadingUpdate && <Spinner />}
          {errorUpdate && (
            <p className="para_main" style={{ color: "red" }}>
              {errorUpdate}
            </p>
          )}
          {loading ? (
            <Spinner />
          ) : error ? (
            <p className="para_main" style={{ color: "red" }}>
              {error}
            </p>
          ) : (
            <Form onSubmit={formSubmitHander}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Hero Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Hero Heading"
                  value={heroHeading}
                  onChange={(event) => setHeroHeading(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Client Name"
                  value={clientName}
                  onChange={(event) => setClientName(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="image">
                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Image URL"
                  value={bannerImage}
                  onChange={(e) => setBannerImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  type="file"
                  id="image-file"
                  label="Choose File"
                  custom
                  onChange={uploadBannerHandler}
                ></Form.Control>
                {uploading && <Spinner />}
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 1 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec1Heading}
                  onChange={(event) => setSec1Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 1 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec1Para}
                  onChange={(event) => setSec1Para(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 1 Paragraph 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec1Para2}
                  onChange={(event) => setSec1Para2(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 1 Banner</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Image URL"
                  value={sec1Img}
                  onChange={(e) => setSec1Img(e.target.value)}
                ></Form.Control>
                <Form.Control
                  type="file"
                  id="image-file"
                  label="Choose File"
                  custom
                  onChange={uploadImg1Handler}
                ></Form.Control>
                {uploading && <Spinner />}
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 2 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec2Heading}
                  onChange={(event) => setSec2Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 2 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec2Para}
                  onChange={(event) => setSec2Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 3 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec3Heading}
                  onChange={(event) => setSec3Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 3 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec3Para}
                  onChange={(event) => setSec3Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 4 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec4Heading}
                  onChange={(event) => setSec4Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 4 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec4Para}
                  onChange={(event) => setSec4Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 5 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec5Heading}
                  onChange={(event) => setSec5Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 5 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec5Para}
                  onChange={(event) => setSec5Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 6 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec6Heading}
                  onChange={(event) => setSec6Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 6 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec6Para}
                  onChange={(event) => setSec6Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 7 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec7Heading}
                  onChange={(event) => setSec7Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 7 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec7Para}
                  onChange={(event) => setSec7Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 8 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec8Heading}
                  onChange={(event) => setSec8Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 8 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec8Para}
                  onChange={(event) => setSec8Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 9 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec9Heading}
                  onChange={(event) => setSec9Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 9 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec9Para}
                  onChange={(event) => setSec9Para(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 10 Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec10Heading}
                  onChange={(event) => setSec10Heading(event.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="text">
                <Form.Label>Section 10 Paragraph</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Text"
                  value={sec10Para}
                  onChange={(event) => setSec10Para(event.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category 1</Form.Label>
                <Form.Select
                  type="text"
                  value={category1}
                  onChange={(e) => setCategory1(e.target.value)}
                >
                  <option value="">Not selected</option>
                  {
                    caseStudyCatagoryAr.map((catagory)=>{
                      return(
                        <option value={`${catagory}`}>{catagory}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category 2</Form.Label>
                <Form.Select
                  type="text"
                  value={category2}
                  onChange={(e) => setCategory2(e.target.value)}
                >
                  <option value="">Not selected</option>
                  {
                    caseStudyCatagoryAr.map((catagory)=>{
                      return(
                        <option value={`${catagory}`}>{catagory}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category 3</Form.Label>
                <Form.Select
                  type="text"
                  value={category3}
                  onChange={(e) => setCategory3(e.target.value)}
                >
                  <option value="">Not selected</option>
                  {
                    caseStudyCatagoryAr.map((catagory)=>{
                      return(
                        <option value={`${catagory}`}>{catagory}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category 4</Form.Label>
                <Form.Select
                  type="text"
                  value={category4}
                  onChange={(e) => setCategory4(e.target.value)}
                >
                  <option value="">Not selected</option>
                  {
                    caseStudyCatagoryAr.map((catagory)=>{
                      return(
                        <option value={`${catagory}`}>{catagory}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>
              <br></br>
              <Button type="submit" variant="primary">
                Update
              </Button>
            </Form>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default CaseStudyEditScreen;
