import React from 'react'
import "./BlogInnerHeroBanner.css";
import Header from '../Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import NavLinks from '../NavLinks/NavLinks';
import "../HeaderBanner.css"

const catagories = [
    {
        name:"All",
        num:0
    },
    {
        name:"UI/UX",
        num:2
    },
    {
        name:"SEO",
        num:3
    },
    {
        name:"SMM",
        num:4
    },
    {
        name:"GOOGLE ADS",
        num:5
    },
]

function HeaderBanner({display}) {
    const dispatch = useDispatch()
  return(
    <div className={`HeaderBanner-container  w-100  ${display ? "disp-none" : "disp-show"} `} >
        
        <div className='row h-100 w-100'>
            
            <div className='col-lg-6 left-pane py-5'>
                <div className='icon-container h-100'>
                    <div className='d-flex flex-column align-items-start h-100 px-5'>
                        <img src='/images/icons/nav-logo.svg' alt='brand logo'/>

                        <div className='text-content mt-auto w-50'>
                            <p className='body-paragraph color-blue my-3'>Canada Ontario — 789 18h Street, Office Interlaken, SL 83366</p>

                            <a href='tel:+(02)-094-980-548' className='color-blue body-paragraph anchor'>+(02)-094-980-548</a>

                            <br></br>
                            <a href='mailto:hello@salmanbaig.me' className='color-blue body-paragraph mail-anchor'>hello@salmanbaig.me</a>
                            <br></br>
                            <br className='d-none d-xxl-block'></br>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
                                <img className='social-icons' alt='social icon' src='/images/icons/fb-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/insta-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/x-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/li-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/yt-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/tt-icon-blue.png'/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='col-lg-6 right-pane py-5'>
                <div className='icon-container h-100 d-flex flex-column px-5'>
                        <img src='/images/icons/cross-icon.svg' style={{marginLeft:"auto"}} 
                            alt='cross icon'
                            onClick={()=>{
                                dispatch({type:"TOGGLE_OPEN",payload:{}})
                               
                              }}
                        />
                   <div className='d-flex flex-column align-items-start nav-main h-100 pt-4'>

                        <div className='nav-links-container w-100'>
                            <NavLinks/>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
  )
}

function BlogInnerHeroBanner({
    heading="",
    date="",
    catagory=0
}) {
    const bannerSelector = useSelector(state => state.banner)
    
  return (
    <div className='BlogInnerHeroBanner-container '>
        <div className=' h-100'>
            
        {<HeaderBanner display={bannerSelector}/>}
        <Header
            logoLight={true}
        />
        <div className='container  h-100 d-flex justify-content-center align-items-center '>
            <div className='main py-5'>

                <div className='text-content text-center py-4'>
                    <div className='blog-details-container d-flex justify-content-center my-1 my-xxl-4'>
                        <p className='body-paragraph'>{catagories.find(cat=>cat.num==catagory).name}</p>
                        <p className='body-paragraph'>{date}</p>

                    </div>
                    <h1 className='main-heading font-bebus hero-heading-font-size color-yellow'>
                        {heading}
                    </h1>

                    {/* <p className='body-paragraph color-white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p> */}
                </div>

                

            </div>
        </div>
        </div>

    </div>
  )
}

export default BlogInnerHeroBanner