import React from 'react'
import "./BlogContent.css"

function BlogContent({text, blogsData}) {
  return (
    <div className='BlogContent-container py-5'>
        <div className='container my-3'>

        <div className={`body-paragraph my-3 color-white`} dangerouslySetInnerHTML={{ __html: text }}></div>
        {blogsData.map((item) => (
          <>
            <h2 className="body-heading color-white" style={{textTransform:"capatalize"}}>{item.heading}</h2>
            <div className={`body-paragraph my-3 color-white`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
          </>
        ))}

        </div>

    </div>
  )
}

export default BlogContent