import React from 'react'
import "./HomeHeroBanner.css"
import Header from '../Header/Header'
import NavLinks from '../NavLinks/NavLinks'
import { useDispatch,useSelector } from 'react-redux'
import "../HeaderBanner.css"
import { useNavigate } from 'react-router-dom'

function HeaderBanner({display}) {
    const dispatch = useDispatch()
  return(
    <div className={`HeaderBanner-container  w-100  ${display ? "disp-none" : "disp-show"} `} >
        
        <div className='row h-100 w-100'>
            
            <div className='col-lg-6 left-pane py-5'>
                <div className='icon-container h-100'>
                    <div className='d-flex flex-column align-items-start h-100 px-5'>
                        <img src='/images/icons/nav-logo.svg' alt='brand logo'/>

                        <div className='text-content mt-auto w-50'>
                            <p className='body-paragraph color-blue my-3'>Canada Ontario — 789 18h Street, Office Interlaken, SL 83366</p>

                            <a href='tel:+(02)-094-980-548' className='color-blue body-paragraph anchor'>+(02)-094-980-548</a>

                            <br></br>
                            <a href='mailto:hello@salmanbaig.me' className='color-blue body-paragraph mail-anchor'>hello@salmanbaig.me</a>
                            
                            <br></br>
                            <br className='d-none d-xxl-block'></br>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
                                <img className='social-icons' alt='social icon' src='/images/icons/fb-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/insta-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/x-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/li-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/yt-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/tt-icon-blue.png'/>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className='col-lg-6 right-pane py-5'>
                <div className='icon-container h-100 d-flex flex-column px-5'>
                        <img src='/images/icons/cross-icon.svg' style={{marginLeft:"auto"}} 
                            alt='cross icon'
                            onClick={()=>{
                                dispatch({type:"TOGGLE_OPEN",payload:{}})
                               
                              }}
                        />
                    <div className='d-flex flex-column align-items-start nav-main h-100 pt-4'>

                        <div className='nav-links-container w-100'>
                            <NavLinks/>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
  )
}

function HomeHeroBanner() {
    const bannerSelector = useSelector(state => state.banner)
    const navigate = useNavigate()
  return (
    <div className='HomeHeroBanner-container'>
        <div className='overlay h-100'>
        {<HeaderBanner display={bannerSelector}/>}
        <Header/>
        <div className='container h-100 d-flex align-items-center justify-content-center'>
            <div className='main py-5'>

                <div className='text-content text-center py-4'>
                    {/* <h1 className='main-heading font-bebus hero-heading-font-size'>
                        <span className='color-yellow font-bold ani-tb'><span className='color-blue'>I'M </span>SALMAN<img src='/images/icons/home-hero-text-1-icon.png' className='mx-2 img-1' alt='person icon' style={{height:"85px",width:"85px"}}/><span >BAIG,</span></span>
                        <br></br>
                        <span className='color-yellow font-bold'><span  className='ani-lr'><span className='color-blue font-bold'>A </span>DIGITAL</span><img src='/images/icons/home-hero-text-2-icon.png' className='mx-2 ani-fade img-2' alt='person icon' style={{height:"80px",width:"100px"}}/><span className='ani-rl' >MARKETIER</span></span>
                        <br></br>
                        <span className='color-yellow font-bold ani-bt'><span className='color-blue font-bold'>FROM</span><img src='/images/icons/home-hero-text-3-icon.png' className='mx-2 img-3' alt='person icon' style={{height:"100px",width:"50px"}}/><span >CANADA</span></span>

                    </h1> */}
                    <h1 className='main-heading font-bebus hero-heading-font-size'>
                        <span className='color-yellow font-bold ani-tb'><span className='color-blue'>MEET </span>SALMAN<img src='/images/icons/home-hero-text-1-icon.png' className='mx-2 img-1' alt='person icon' style={{height:"85px",width:"85px"}}/><span >BAIG:</span></span>
                        <br></br>
                        <span className='color-yellow font-bold'><span  className='ani-lr'><span className='color-blue font-bold'>YOUR CATALYST </span>FOR </span><img src='/images/icons/home-hero-text-2-icon.png' className='mx-2 ani-fade img-2' alt='person icon' style={{height:"80px",width:"100px"}}/><span className='ani-rl' >DIGITAL TRANSFORMATION</span></span>
                        <br></br>
                        <span className='color-yellow font-bold ani-bt'><span className='color-blue font-bold'>AND</span><img src='/images/icons/home-hero-text-3-icon.png' className='mx-2 img-3' alt='person icon' style={{height:"100px",width:"50px"}}/><span >SUCCESS?!</span></span>

                    </h1>
                </div>

                <div className='btn-row mt-4 mb-4 my-4'>
                    <div className='d-flex flex-column flex-md-row align-items-center justify-content-center gap-5'>
                        <button className='genral-btn btn-blue ani-lr' onClick={()=>{navigate("/case-study")}}>
                            VIEW ALL WORK
                        </button>
                        <button className='genral-btn btn-trans ani-rl' onClick={()=>{navigate("/contact")}}>
                           GET IN TOUCH
                        </button>
                    </div>
                </div>

            </div>
        </div>
        </div>
    </div>
  )
}

export default HomeHeroBanner