import React from 'react'
import "./FollowMyWork.css"

function FollowMyWork() {
  return (
    <div className='FollowMyWork-container pt-5'>
        <div className='container mt-4 mt-xxl-5'>
            <div className='row gy-4 py-3 mb-3'>
                <div className='col-lg-6 text-lg-start text-center'>
                    <h3 className='body-heading color-blue '>Follow me on <span className='color-yellow'>Instagram</span> </h3>
                </div>
                
                <div className='col-lg-6 text-lg-start text-center'>
                    <p className='body-paragraph '>Let's connect on Instagram and spread those good vibes together!</p>
                    <button className='genral-btn btn-blue mt-4' onClick={()=>{}}>Follow Me</button>
                </div>
            </div>
        <img src='/images/creatives\FollowMyWork.png' alt='design ' className='w-100'/>
        </div>
    </div>
  )
}

export default FollowMyWork