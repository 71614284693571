import React from 'react'
import TextContent from '../components/TextContent/TextContent'
import Footer from '../components/Footer/Footer'
import TextListContent from '../components/TextListContent/TextListContent'
import LetsWorkTogether from "../components/letsWorkTogether/letsWorkTogether"
import GAdsHeroBanner from '../components/GAdsHeroBanner/GAdsHeroBanner'

function GoogleAds() {
    return (
      <div>
          <GAdsHeroBanner/>

          <TextContent
            img="/images/creatives/gads-text-1.png"
            heading="Dynamic Google "
            headingYellow="Ads Campaigns"
            paragraph="Experience the power of our expert Google Ads services. With a strategic blend of keyword research, compelling ad copy, and continuous optimization, we ensure your campaigns resonate with your audience and deliver measurable results. Our data-driven approach guarantees that your ads reach the right people at the right time, maximizing your ROI and achieving your business goals. Whether you're aiming to increase brand awareness, drive sales, or expand your customer base, our Google Ads solutions are customized to meet your unique needs and drive your success to new heights."
            btn={true}
            btnText="LET'S CONNECT"
            reverse={true}
          btnRef='/contact'
          />
  
          <TextListContent
            img="/images/creatives/gads-text-2.png"
            heading="Engaging "
            headingYellow="Google Ads"
            paragraph="Experience the power of captivating Google Ads that speak directly to your audience's needs and desires. Benefit from:"
            btn={false}
            reverse={false}
            listData={[
              "Compelling Ad Copy",
              "Targeted Audience Reach",
              "Creative Ad Formats",
              "Continuous Optimization",
              "Measurable Results",
            ]}
          />

          <LetsWorkTogether />

        <Footer/>
    </div>
  )
}

export default GoogleAds