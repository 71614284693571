import React from 'react'
import './MyAcievment.css'

function MyAcievment() {
  return (
    <div className='MyAcievment-container py-5'>
        <div className='container my-4'>
            <div className='text-content'>
                <div className='row'>
                    <div className='col-lg-6'> 
                        <h3 className='body-heading color-blue'>MY <span className='color-yellow'>ACHIEVEMENTS</span></h3>
                    </div>
                    {/* <div className='col-lg-6'> 
                        <p className='body-paragraph content'>Lorem ipsum dolor sit amet in quis, consectetur eros adipiscing et elit. Suspendisse varius enim in eros elementum tristique. Duis cursuat.</p>
                    </div> */}
                </div>

                <div className='row pt-5 gy-3'>
                    <div className='col-lg-4'>
                        <h5 className='title font-bebus'>PROJECTS DONE</h5>
                        <h3 className='body-heading color-blue large-num-text'>124<span style={{fontSize:"70px"}}>+</span></h3>
                    </div>
                    <div className='col-lg-4'>
                        <h5 className='title font-bebus'>YEARS OF EXPERIENCE</h5>
                        <h3 className='body-heading color-blue large-num-text'>08<span style={{fontSize:"70px"}}></span></h3>
                    </div>
                    <div className='col-lg-4'>
                        <h5 className='title font-bebus'>NUMBER OF CLIENTS</h5>
                        <h3 className='body-heading color-blue large-num-text'>60<span style={{fontSize:"70px"}}>+</span></h3>
                    </div>

                </div>
            </div>

        </div>
    </div>
  )
}

export default MyAcievment