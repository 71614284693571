import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './screen/Home';
import About from './screen/About';
import Ui from './screen/Ui';
import Seo from "./screen/Seo"
import GoogleAds from './screen/GoogleAds';
import SocialMediaMarketing from './screen/SocialMediaMarketing';
import CaseStudies from './screen/CaseStudies';
import CaseStudyInner from './screen/CaseStudyInner';
import Blogs from './screen/Blogs';
import Contact from './screen/Contact';
import BlogInner from './screen/BlogInner';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screen/LocationScreens/LocationScreen';
import LocationPages from "./components/LocationPages_/LocationPages"
import CaseStudyEditScreen from "./AdminScreens/CaseStudyScreens/CaseStudyEditScreen";
import CaseStudyListScreen from "./AdminScreens/CaseStudyScreens/CaseStudyListScreen";
import NotFound from "./screen/NotFound"

function App() {
  return (
    <div className="App">
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to={"/"} />} />
        <Route path="/about" element={<About />} />
        <Route path="/user-interface" element={<Ui />} />
        <Route path="/search-engine-optimization" element={<Seo />} />
        <Route path="/google-ads" element={<GoogleAds />} />
        <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />

        <Route path="/case-study" element={<CaseStudies />} />
        <Route path="/casestudy/:id" element={<CaseStudyInner />} />

        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogInner />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<NotFound />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        
        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
        
          <Route path="/admin/casestudy/:id/edit" element={<CaseStudyEditScreen />}/>
          <Route path="/admin/casestudy" element={<CaseStudyListScreen />} />
      </Routes>
    </div>
  );
}

export default App;
