import React from 'react'
import "./TextListContent.css"

function TextListContent({
    img="",
    heading="",
    headingYellow,
    paragraph,
    btn=false,
    btnText,
    reverse=false,
    listData=null,
}) {
  return (
    <div className='TextListContent-container py-5'>
        <div className='container my-4 p-5'>
            <div className={`main row align-items-center gy-3 ${reverse && "flex-row-reverse"}`}>
                <div className='img-container col-lg-6 px-4'>
                    <img src={img} className='img-fluid' alt='text content ' />
                </div>

                <div className='text-content col-lg-6 text-center text-lg-start'>
                    <h3 className='font-bebus body-heading ' style={{color:"white"}}>
                        {heading}{headingYellow&&<span className='color-yellow'>{headingYellow}</span>}
                    </h3>
                    <p className='body-paragraph font-manrope my-4' style={{color:"#BBBCBC"}}>{paragraph}</p>

                    {
                        listData &&
                        <div className='row'>
                            {listData.map((ele)=>{
                                return(
                                    <div className='col-lg-12'>
                                        <div class="d-flex align-items-center  my-2 icon-list gap-2 " ><img src="/images/icons/list-card-bullets.png" alt="icon" class="list-bullet"/><p class="body-paragraph my-0 list-para">{ele}</p></div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {btn && <button className='genral-btn btn-yellow mt-2'>{btnText}</button>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextListContent