import React,{useState,useEffect,useRef} from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';

function TextContent({
    img="",
    heading="",
    headingYellow,
    paragraph,
    btn=false,
    btnText,
    btnRef="/",
    reverse=false,
    outerLink=false
}) {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const navigate =useNavigate()
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        { threshold: 0.1 } 
      );
  
      if (elementRef.current) {
        observer.observe(elementRef.current);
      }
  
      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }, []);
  
  return (
    <div className='TextContent-container py-5' ref={elementRef}>
        <div className='container my-4'>
            <div className={`main row align-items-center gy-3 ${reverse && "flex-row-reverse"}`}>
                <div className='img-container col-lg-6 px-4 text-center'>
                    <img src={img} className={`img-fluid ${reverse ? "ani-rl":"ani-lr" } ${isVisible && "show-animation"}`} alt='text content ' />
                </div>

                <div className={`text-content col-lg-6 text-center text-lg-start ${reverse ? "ani-lr":"ani-rl" }  ${isVisible && "show-animation"}`}>
                    <h3 className='font-bebus body-heading ' style={{color:"white"}}>
                        {heading}{headingYellow&&<span className='color-yellow'>{headingYellow}</span>}
                    </h3>
                    <p className='body-paragraph font-manrope my-4' style={{color:"#BBBCBC"}}>{paragraph}</p>
                    {
                      outerLink ?
                      <a href={btnRef} target='blank'>
                        {btn && <button className='genral-btn btn-yellow mt-2'>{btnText}</button>}
                      </a>
                      :
                      <>
                      {btn && <button className='genral-btn btn-yellow mt-2' onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                      </>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextContent