import React,{useState,useEffect} from 'react'
import "./BlogsListing.css"
import PopularBlogs from "../PopularBlogs/PopularBlogs"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

const catagory = [
    {
        name:"All",
        num:0
    },
    {
        name:"UI/UX",
        num:2
    },
    {
        name:"SEO",
        num:3
    },
    {
        name:"SMM",
        num:4
    },
    {
        name:"GOOGLE ADS",
        num:5
    },
]

let defaultBlogObj ;

function BlogsListing() {
    const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const[firstBlog,setFirstBlog] = useState()

  const [loading, setLoading] = useState(false);
  
  const [selCatagory,setSelCatagory] = useState({
    name:"All",
    num:0
    })

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          const [first , ...otherBlog] = res.data.data;
          setFirstBlog(first)
          otherBlog.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          otherBlog.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);
    navigate(`/blog/${slug}`);

  };



  return (
    <div className='BlogsListing-container py-5'>
        <div className='container my-5'>
          {
            loading ?
            <div
                style={{ width: "100%", height: "100vh" }}
                className="d-flex justify-content-center align-items-center"
            >
                <Spinner
                style={{ color: "black", width: "120px", height: "120px" }}
                />
            </div>
            :
            <>
              {
                firstBlog &&
                <div className='latest-blog-container p-5 my-5'>
                          <div className='row align-items-center gy-3' >
                          <>
                              <div className='col-lg-6 px-lg-5 px-3'>
                                  <img 
                                      src={firstBlog.blog_image}
                                      className="img-fluid w-100 "
                                      alt='content '
                                  />
                              </div>
          
                              <div className='col-lg-6'>
                                  <div className='text-content px-2 px-lg-3 px-xxl-5'>
                                      <p className='body-paragraph d-flex align-items-center gap-2' style={{color:"#EAEBEB"}}>Design  <div style={{border:"1px solid #EAEBEB",width:"35px"}}></div>  {firstBlog.published_date}</p>
          
                                      <h2 className='font-manarope color-white my-3 mt-4 ' style={{lineHeight:"42px"}}>{firstBlog.title}</h2>
          
                                      <p className='body-paragraph content' style={{color:"#EAEBEB"}}>{firstBlog.brief_paragraph}</p>
                                      
                                      <div className='d-flex align-items-center gap-2 mt-4'
                                      onClick={()=>{redirectUserToBlog(firstBlog.slug_url)}}
                                      >
                                          <p className='body-paragraph mb-0 blog-card-anchor'>Read Article</p>
                                          <img src='./images/icons/case-st-card-icon.svg' alt="arrow icon" className='blog-card-icon'/>
                                      </div>
                                  </div>
          
                              </div>

                          </>
                      </div>

              </div>
              }
              {
                blogs &&
                <div className='my-5 main-listing-container pt-5'>
                <div className='blog-Catagory '>
                <div className='containe '>
                    <div className='main-container ' >

                        <div className='row'>
                            <div className='col-lg-6'>
                                <h3 className='body-heading color-white'>Latest <span className='color-yellow'>news</span></h3>
                            </div>
                            <div className='col-lg-6'>
                                <div 
                                    className='d-flex flex-column flex-lg-row gap-2 gy-2 justify-content-center justify-content-lg-end  main-catagory-container mx-3 mx-lg-0' 
                                >
                                    {
                                        catagory.map((ele)=>{
                                            return(
                                            <div 
                                                className={` catagory-container ${ele.num == selCatagory.num ? "active" : ""} `}
                                                onClick={()=>{setSelCatagory(ele)}}    
                                            >
                                                <h4 className='font-bebus m-0' >{ele.name}</h4>
                                            </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <PopularBlogs
                    selectedCatagory={selCatagory.num}
                    blogsData= {blogs}
                    catagoryList={catagory}
            />

                </div>
              }
            </>
          }


        </div>
    </div>
  )
}

export default BlogsListing