import React,{useEffect,useState} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import NavLinks from '../NavLinks/NavLinks';


function HeaderBanner({display}) {
  const dispatch = useDispatch()
return(
  <div className={`HeaderBanner-container  w-100  ${display ? "disp-none" : "disp-show"} `} >
      
      <div className='row h-100 w-100'>
          
          <div className='col-lg-6 left-pane py-5'>
              <div className='icon-container h-100'>
                  <div className='d-flex flex-column align-items-start h-100 px-5'>
                      <img src='/images/icons/nav-logo.svg' alt='brand logo'/>

                      <div className='text-content mt-auto w-50'>
                          <p className='body-paragraph color-blue my-3'>Canada Ontario — 789 18h Street, Office Interlaken, SL 83366</p>

                          <a href='tel:+(02)-094-980-548' className='color-blue body-paragraph anchor'>+(02)-094-980-548</a>

                          <br></br>
                            <a href='mailto:hello@salmanbaig.me' className='color-blue body-paragraph mail-anchor'>hello@salmanbaig.me</a>
                            <br></br>
                            <br className='d-none d-xxl-block'></br>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
                                <img className='social-icons' alt='social icon' src='/images/icons/fb-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/insta-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/x-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/li-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/yt-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/tt-icon-blue.png'/>
                            </div>
                      </div>
                  </div>

              </div>
          </div>

          <div className='col-lg-6 right-pane py-5'>
              <div className='icon-container h-100 d-flex flex-column px-5'>
                      <img src='/images/icons/cross-icon.svg' style={{marginLeft:"auto"}} 
                          alt='cross icon'
                          onClick={()=>{
                              dispatch({type:"TOGGLE_OPEN",payload:{}})
                             
                            }}
                      />
                 <div className='d-flex flex-column align-items-start nav-main h-100 pt-4'>

                      <div className='nav-links-container w-100'>
                          <NavLinks/>
                      </div>
                  </div>

              </div>
          </div>

      </div>

  </div>
)
}

const HeroBanner = ({
  Heading,
  subHeading,
  spanHeading="",
  Paragraph,
  btnText,
  bgImg,
  bgVideo=false,
  btn=false,
  btnRef="/",
  socialLinks=false,
  serviceForm=false,
  bgEffect=false,
  heightAuto=false,
  smallHeading=false,
  sideLinks=true
}) => {
  const navigate = useNavigate()
  const paragraphs = Paragraph.split('\n').map((paragraph, index) => <p key={index} className='hero-paragraph mx-auto' style={{width:"80%"}}>{paragraph}</p>);
  
  const bannerSelector = useSelector(state => state.banner)
  return (
    <div
      className={`common_hero_section ${bgVideo && "bg-video"} ${heightAuto && "h-cus-auto"} mb-5`}
      style={{ backgroundImage: `url(${bgImg})`}}
        
    >
      <div className={`${bgEffect && "bg-effect"} h-100`}>
       { bgVideo && <video autoPlay loop muted>
        <source src="./images/backgroundVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>}

      <div className="hero-container h-100  px-0"
      
      >
     <div className="wrapper d-flex flex-column pt-xl-3 pt-0">

     {<HeaderBanner display={bannerSelector}/>}
     <Header/>
      <div 
        className=" d-flex align-items-center py-5 my-auto container"
        >

        {sideLinks &&
        
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <h3 className="verticle-text" style={{color:"white"}}>SCROLL</h3>
          <div className="" style={{border:"1.5px solid white", width:"0" , height:"100px" }}></div>
        </div>
        }

        <div className="gy-2 w-100">
          <div
            className={
              serviceForm
                ? "col-md-6 "
                : "col-12 hero_content_wrapper px-3 w-100"
            }
          >
            <div className="hero-text-content w-100 text-center">
            <h5 className="hero-sub-heading">{subHeading}</h5>
            <h1 className={`hero-section-heading my-3 ${smallHeading && "hero-section-heading-small"}`}>
              {Heading}
              <span className="heading-color-change">{spanHeading}</span>
            </h1>
            <p className="hero-paragraph mx-auto">{paragraphs}</p>
            {btn && <button className="genral-btn mt-4" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
            {socialLinks && (
              <div className="social-links py-2">
                <div className="first-row d-flex gap-3">
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-1.png" />
                    <p className="m-0">647-948-8750</p>
                  </div>
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-2.png" />
                    <p className="m-0">416-904-2013</p>
                  </div>
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-3.png" />
                  <p className="m-0">info@lotusaccounting.ca</p>
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-4.png" />
                  <p className="m-0">Suite 304-305 Milner Ave. Toronto, ON M1B 3V4</p>
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-5.png" />
                  <p className="m-0">Mon-Sat 10:00AM to 5:00PM Eastern Time</p>
                </div>
              </div>
            )}
          </div>
          </div>
          {serviceForm && (
            <div className="col-md-6 pb-5">
              <div className="service-form d-flex flex-column px-5 py-5">
                <h3 className="body-heading" style={{ color: "black" }}>
                  Let’s get in touch!
                </h3>
                <p
                  className="body-paragraph text-start"
                  style={{ color: "black" }}
                >
                  Let’s discuss your project and find out what we
                </p>
                <input type="text" placeholder="Your Full Name" />
                <input type="text" placeholder="Your Email Address" />
                <input type="text" placeholder="Phone Number" />
                <input type="text" placeholder="Your Message" />
                <button className="service-from-btn  blue-btn">Submit</button>
              </div>
            </div>
          )}
        </div>
        {
        sideLinks &&
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <div className="icon-container d-flex flex-column align-items-center gap-3">
              <a 
                href="https://www.facebook.com/meadowsequine/"
                target="blank"  
              >
                <img src="/images/icons/hero-fb-icon.svg" />
              </a>

              <a 
                href="https://www.instagram.com/meadows.equine/"
                target="blank"  
              >
                <img src="/images/icons/hero-insta-icon.svg" />
              </a>
            </div>
            <div className="" style={{border:"1.5px solid white", width:"0" , height:"120px" }}></div>
          </div>
        }
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default HeroBanner; 