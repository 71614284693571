import React, { useEffect, useState } from 'react'
import "./CaseStudyCard.css"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listCaseStudy } from '../../store/actions/caseStudyActions'
import { Spinner } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}

function CardBox({
    img,
    title="",
    catagory="",
    content="",
    id="",
    handlePrevButtonClick=()=>{},
    handleNextButtonClick=()=>{}
}) {
    const navigate =useNavigate()
    let shortText = content;

    if(window.screen.width <= 768)
    {
        shortText = truncateText(content,150)
    }
    else{
        shortText = truncateText(content,600)
    }
    return(
        <div className='CardBox-container p-5'>
            <img src={img} alt='case study thumbnail' className='img-fluid card-thumb'/>

            <div className='row mt-5'>
                <div className='col-lg-6'>
                    <h3 className='body-heading card-title' style={{color:"white"}}>{title}</h3>
                    <p className='body-paragraph color-yellow' style={{color:"#f58e09"}}>{catagory}</p>
                </div>

                <div className='col-lg-6'>
                    <p className='body-paragraph' style={{color:"#BBBCBC"}}>{shortText}</p>
                </div>
            </div>

            <div className='line-seprater my-4 my-xxl-5'></div>
            
            <div className='row pt-4 '>
                <div className='col-lg-6 text-center text-lg-start'
                    onClick={()=>{navigate(`/casestudy/${id}`)}}
                >
                    <button className='genral-btn btn-yellow'>SEE FULL PROJECT</button>
                </div>

                <div className='col-lg-6 '>
                    <div className='d-flex gap-3 align-items-center justify-content-center justify-content-lg-end mt-4 mt-lg-0'>
                        <img 
                            src='/images/icons/cs-arrow-left.svg' 
                            alt='arrow button'
                            className='case-card-arrow'
                            onClick={handlePrevButtonClick}
                        />
                        <img 
                            src='/images/icons/cs-arrow-right.svg' 
                            alt='arrow button'
                            className='case-card-arrow'
                            onClick={handleNextButtonClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CaseStudyCard() {
    const navigate =useNavigate()
    const dispatch = useDispatch();
  
    const caseStudyList = useSelector((state) => state.caseStudyList);
    const { loading, error, caseStudies } = caseStudyList;
  
    useEffect(() => {
        dispatch(listCaseStudy());
        
      }, []);

      const handlePrevButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slideNext();
      };

  return (
    <div className='CaseStudyCard-container py-5'>
        <div className='container my-4 pt-4'>
            <div className='text-content'>
                <div className='row gy-3 align-items-center'>
                    <div className='col-lg-6'>
                        <h3 className='body-heading font-bebus text-lg-start text-center' style={{color:"white"}}>MY <span className='color-yellow'>CASE STUDIES</span></h3>
                        {/* <p className='body-paragraph font-manrope' style={{color:"#BBBCBC"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit at arcu, tempor.</p> */}
                    </div>

                    <div className='col-lg-6 text-center text-lg-end'>
                        <button className='genral-btn btn-yellow' onClick={()=>{navigate("/case-study")}}>SEE ALL</button>
                    </div>
                </div>

            </div>
                 {loading ? (
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        style={{
                          color: "white",
                          width: "120px",
                          height: "120px",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      />
                    </span>
                  ) :
                  caseStudies && (

            <div className='card-carousel'>
                <Swiper
                    spaceBetween={50}
                    autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    navigation={
                        {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        }
                        }
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        }
                    }}
                    pagination={{ clickable: true }}
                    observer ={true}
                    observeParents={true}
                    parallax={true}
                    className=" my-5 px-3 px-md-5 py-4"
                    style={{position:"relative"}}
                    id="service-cards-slider"
                    >
                        {
                            caseStudies.map((caseSt)=>{
                                return(
                                    <SwiperSlide>
                                        <div className='case-study-card'>
                                            <CardBox
                                                img={caseSt.bannerImage}
                                                title={caseSt.heroHeading}
                                                catagory={caseSt.category1}
                                                content={caseSt.sec1Para}
                                                id={caseSt._id}
                                                handlePrevButtonClick={handlePrevButtonClick}
                                                handleNextButtonClick={handleNextButtonClick}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                         {/* <div className='case-study-card'>
                            <CardBox
                                img={"/images/creatives/case-study-card-1.jpg"}
                                title="MOBILE APPLICATION"
                                catagory="Charu Immigration"
                                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit at arcu, tempor, gravida morbi amet hendrerit hendrerit non pharetra."
                                handlePrevButtonClick={handlePrevButtonClick}
                                handleNextButtonClick={handleNextButtonClick}
                            />
                        </div> */}
                       
                </Swiper>

            </div>
                  )}

        </div>
    </div>
  )
}

export default CaseStudyCard