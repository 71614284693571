import React from 'react'
import "./CaseStudyInnerHeroBanner.css";
import Header from '../Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import NavLinks from '../NavLinks/NavLinks';
import "../HeaderBanner.css"

function HeaderBanner({display}) {
    const dispatch = useDispatch()
  return(
    <div className={`HeaderBanner-container  w-100  ${display ? "disp-none" : "disp-show"} `} >
        
        <div className='row h-100 w-100'>
            
            <div className='col-lg-6 left-pane py-5'>
                <div className='icon-container h-100'>
                    <div className='d-flex flex-column align-items-start h-100 px-5'>
                        <img src='/images/icons/nav-logo.svg' alt='brand logo'/>

                        <div className='text-content mt-auto w-50'>
                            <p className='body-paragraph color-blue my-3'>Canada Ontario — 789 18h Street, Office Interlaken, SL 83366</p>

                            <a href='tel:+(02)-094-980-548' className='color-blue body-paragraph anchor'>+(02)-094-980-548</a>

                            <br></br>
                            <a href='mailto:hello@salmanbaig.me' className='color-blue body-paragraph mail-anchor'>hello@salmanbaig.me</a>
                            <br></br>
                            <br className='d-none d-xxl-block'></br>
                            <div className='d-flex align-items-center jusstify-content-center gap-3 mt-3'>
                                <img className='social-icons' alt='social icon' src='/images/icons/fb-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/insta-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/x-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/li-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/yt-icon-blue.png'/>
                                <img className='social-icons' alt='social icon' src='/images/icons/tt-icon-blue.png'/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='col-lg-6 right-pane py-5'>
                <div className='icon-container h-100 d-flex flex-column px-5'>
                        <img src='/images/icons/cross-icon.svg' style={{marginLeft:"auto"}} 
                            alt='cross icon'
                            onClick={()=>{
                                dispatch({type:"TOGGLE_OPEN",payload:{}})
                               
                              }}
                        />
                   <div className='d-flex flex-column align-items-start nav-main h-100 pt-4'>

                        <div className='nav-links-container w-100'>
                            <NavLinks/>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
  )
}

function CaseStudyInnerHeroBanner({
    Heading,
    catagory,
    clientName,
    img=null
}) {
    const bannerSelector = useSelector(state => state.banner)
  return (
    <>
    <div className='CaseStudyInnerHeroBanner-container '>
        <div className='overlay h-100'>
            
        {<HeaderBanner display={bannerSelector}/>}
        <Header
            logoLight={true}
        />
        <div className='container  h-100 d-flex justify-content-center justify-content-lg-start align-items-center '>
            <div className='main py-5'>

                <div className='text-content text-start py-4'>
                    <h1 className='main-heading font-bebus hero-heading-font-size '>
                        <span className='large-screen-img '>
                            
                            <span className='color-yellow font-bold txt-2'>
                            <span className='color-white '>{Heading}</span>
                            </span>
                        </span>

                        
                        
                    </h1>

                    {/* <p className='body-paragraph color-white hero-content'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a typeised in the</p> */}

                    <div className='row my-5 mt-xxl-5 gy-2'>
                        <div className='col-lg-3'>
                            <div className='d-flex flex-column gap-2 '>
                                {/* <h3 className='font-bebus color-yellow mb-1'>Category</h3> */}
                                <h3 className='font-bebus color-yellow mb-1'>Industry</h3>
                                <p className='body-paragraph color-white'>{catagory}</p>
                            </div>
                        </div>
                        <div className='col-lg-1 '>

                            <div className='border-right'></div>
                        </div>
                        <div className='col-lg-3 '>
                            <div className='d-flex flex-column gap-2 '>
                                <h3 className='font-bebus color-yellow mb-1'>Client</h3>
                                <p className='body-paragraph color-white'>{clientName}</p>
                            </div>
                        </div>
                        {/* <div className='col-lg-1 '>
                            <div className='border-right'></div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='d-flex flex-column gap-2 '>
                                <h3 className='font-bebus color-yellow mb-1'>Project Date</h3>
                                <p className='body-paragraph color-white'>NOV 26, 2023</p>
                            </div>
                        </div> */}

                    </div>
                </div>

                

            </div>
        </div>
        </div>

    </div>

    {
        img &&
    <img 
        src={img}
        alt='case study thumbnail'
        className='img-fluid w-100'
    />
    }

    </>
  )
}

export default CaseStudyInnerHeroBanner