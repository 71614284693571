import React from 'react'
import SeoHeroBanner from '../components/SeoHeroBanner/SeoHeroBanner'
import TextContent from '../components/TextContent/TextContent'
import Footer from '../components/Footer/Footer'
import TextListContent from '../components/TextListContent/TextListContent'
import LetsWorkTogether from "../components/letsWorkTogether/letsWorkTogether"

function Seo() {
    return (
      <div>
        <SeoHeroBanner />

        <TextContent
          img="/images/creatives/seo-text-1.png"
          heading="Cutting-Edge "
          headingYellow="SEO Strategies"
          paragraph="Maximize your digital visibility and reach with our comprehensive Search Engine Optimization (SEO) services. Our team of SEO experts utilizes advanced strategies and cutting-edge techniques to increase your website's ranking on major search engines. From keyword research to on-page optimization and link building, we have it all under control to align with your unique business goals. Let’s partner up to stay ahead of the competition and establish your authority in your industry."
          btn={true}
          btnText="LET'S CONNECT"
          reverse={true}
          btnRef='/contact'
        />

        <TextListContent
          img="/images/creatives/seo-text-2.png"
          heading="On-point "
          headingYellow="SEO"
          paragraph="Experience the difference with our On-Point SEO services designed to skyrocket your digital success. With careful attention to detail and a results-driven approach, we ensure your website not only climbs the search engine ranks but maintains its position over time. Benefit from:"
          btn={false}
          reverse={false}
          listData={[
            "Targeted Keyword Optimization",
            "Technical Excellence",
            "Content Enhancement",
            "Link Building Mastery",
            "Data-Driven Insights",
            ]}
        />

        <LetsWorkTogether />

        <Footer />
      </div>
    );
}

export default Seo