import React, { useState } from 'react'
import "./ContactFormSection.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

function ContactFormSection() {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        subject:subject,
        formType: "Message genrated through Contact form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
          // window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };
  return (
    <div className='ContactFormSection-container py-5'>
        <div className='container my-5'>
            <form className='main-form' onSubmit={formSubmitHandler}>
                <div className='row'>
                    <div className='col-lg-6'>
                        <input
                            type='text'
                            placeholder='Full Name'
                            className='none-input'
                            value={name}
                            required
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <input
                            type='text'
                            placeholder='Email Address'
                            className='none-input'
                            value={email}
                            required
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <input
                            type='text'
                            placeholder='Phone Number'
                            className='none-input'
                            value={phone}
                            required
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <input
                            type='text'
                            placeholder='Subject'
                            className='none-input'
                            value={subject}
                            required
                            onChange={(e)=>setSubject(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-12 pb-5'>
                        <textarea
                            placeholder='Write your message here'
                            className='none-input text-none '
                            rows={5}
                            value={message}
                            required
                            onChange={(e)=>setMessage(e.target.value)}
                        ></textarea>
                    </div>

                </div>

                <div className='d-flex flex-column flex-lg-row justify-content-between justify-content-xxl-between align-items-center gap-4'>
                    <button className='genral-btn btn-yellow'>Send Message

                    {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                    </button>
                    <div className='d-flex align-items-center jusstify-content-center gap-3 mt-4 px-lg-5 mx-lg-5'>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-1.svg'/>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-2.svg'/>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-3.svg'/>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-4.svg'/>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-5.svg'/>
                        <img className='social-icons' alt='social icon' src='/images/icons/contact-icon-6.svg'/>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default ContactFormSection